<template>
  <div class="roulette">
    <div class="roulette_wrap">
      <div class="roulette_main">
        <div class="roulette-period">{{ wheelInfo.start_date | shortDate }} - {{ wheelInfo.end_date | shortDate }}</div>
        <div class="roulette_main_numberTimes_left">
          <div>
            <div>
              <span class="font-weight-bold title">{{ wheelInfo.event_setting.coin_required }}</span>
              <span>coins</span>
              <br />
              /swing
            </div>
          </div>
        </div>
        <div class="roulette_main_wrap roulette_P8_N8">
          <ul class="roulette_main_part roulette_main_part-8">
            <li class="roulette_main_part_list" v-for="(n, k) in wheelInfo.event_rewards">
              <div class="roulette_main_part_color"></div>
              <span class="roulette_main_part_award">{{ n.name }}</span>
            </li>
          </ul>
          <div class="roulette_main_btn" :style="{ 'background-image': `url('${wheelInfo.event_setting.start_button_image_url}'` }">
            <!--            <img class="roulette_main_logo" src="/static/svg/logo.png">-->
          </div>
          <div class="roulette_main_numberTimes">
            <div>
              <div>
                <span>{{ memberInfo.rewardBalance ? memberInfo.rewardBalance : 0 }}</span>
                <span>coins</span>
              </div>
            </div>
          </div>
        </div>
        <div class="roulette_main_coin">
          <span class="roulette_main_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/1.png" class="roulette_main_coin_1" /></span>
          <span class="roulette_main_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/3.png" class="roulette_main_coin_2" /></span>
          <span class="roulette_main_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/2.png" class="roulette_main_coin_3" /></span>
          <span class="roulette_main_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/4.png" class="roulette_main_coin_4" /></span>
          <span class="roulette_main_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/5.png" class="roulette_main_coin_5" /></span>
          <span class="roulette_main_coinWrap"><img src="https://jiliwin.9terawolf.com/images/wof/6.png" class="roulette_main_coin_6" alt="krikya-exchange" /></span>
        </div>
      </div>
      <div class="roulette_minor">
        <ul class="roulette_awards">
          <li class="roulette_awards_item" v-for="(n, k) in wheelInfo.event_rewards" v-if="n.name.toLowerCase() != 'miss'">
            <div>
              <div class="roulette_awards_medals"></div>
              <div class="roulette_awards_prize"><img :src="`${n.rewardspot_image_url}`" alt="JAYA9-exchange" /></div>

              <div class="roulette_awards_text">{{ n.name }}</div>
            </div>
          </li>
        </ul>
        <div class="roulette_winner">
          <div class="roulette_winner_wrap">
            <div class="roulette_winner_head winnerList">
              <div class="roulette_winner_headItem" @click="showWinnerList()">Winner's list</div>
              <div class="roulette_winner_headItem" @click="showRecordList()">Winning record</div>
              <div :class="['roulette_winner_headCursor', winList ? 'leftMost' : 'fiftyLeft']"></div>
            </div>
            <div class="roulette_winner_slider">
              <ul class="roulette_winner_list" v-show="winList">
                <li class="roulette_winner_listItem" v-for="(n, k) in wheelLogs">
                  <span class="roulette_winner_date">{{ n.draw_date | shortDate }}</span>
                  <span>{{ n.membercode }}</span>
                  <span class="roulette_winner_prize">{{ parseFloat(n.win_amount).toFixed(2) }}</span>
                </li>
              </ul>
              <ul class="roulette_winner_list" v-show="recordList">
                <li class="roulette_winner_listItem" v-for="(n, k) in wheelMyLogs">
                  <span class="roulette_winner_date">{{ n.draw_date | shortDate }}</span>
                  <span>{{ n.membercode }}</span>
                  <span class="roulette_winner_prize">{{ parseFloat(n.win_amount).toFixed(2) }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="roulette_rule">
          <h3 class="roulette_rule_title">{{ $t(`luckyWheel.t&c`) }}</h3>
          <p class="rouletteRule_text"></p>
          <p>
            <strong>
              <span style="font-size:14px">{{ $t(`luckyWheel.title`) }}</span>
            </strong>
          </p>
          <p>
            <strong>
              <span style="font-size:14px">
                {{ $t(`luckyWheel.subtitle`).replace('{coinRequired}', coinRequired) }}
              </span>
            </strong>
          </p>
          <p></p>
          <p>
            <strong>
              <span style="font-size:14px">{{ $t(`luckyWheel.howCollect`) }}</span>
            </strong>
          </p>
          <p>
            {{ $t(`luckyWheel.ptsCollect`) }}
          </p>
          <p>
            <span style="font-size:14px">
              {{ $t(`luckyWheel.requiredCoin`).replace('{coinRequired}', coinRequired) }}
            </span>
          </p>
          <p></p>
          <p>
            <strong>
              <span style="font-size:14px">{{ $t(`luckyWheel.t&c`) }}</span>
            </strong>
          </p>
          <p>
            <span style="font-size:14px">1.{{ $t(`luckyWheel.ulOne`).replace('{coinRequired}', coinRequired) }}</span>
          </p>
          <p>
            <span style="font-size:14px">2. {{ $t(`luckyWheel.ulTwo`) }}</span>
          </p>
          <p>
            <span style="font-size:14px">3. {{ $t(`luckyWheel.ulThree`) }}</span>
          </p>
          <p>
            <span style="font-size:14px">4. {{ $t(`luckyWheel.ulFour`) }}</span>
          </p>
          <p>
            <span style="font-size:14px">5. {{ $t(`luckyWheel.ulFive`) }}</span>
          </p>
          <p>
            <span style="font-size:14px">6. {{ $t(`luckyWheel.ulSix`) }}</span>
          </p>
          <p>
            <strong>
              <span style="font-size:14px">{{ $t(`luckyWheel.ulSeven`) }}</span>
            </strong>
          </p>
        </div>
      </div>
    </div>
    <div class="roulette_nav">
      <ul class="roulette_nav_wrap">
        <li class="roulette_nav_item"><a class="active" href="/">Home</a></li>
      </ul>
    </div>
    <div class="roulette_popup">
      <div class="roulette_popup_wrap">
        <div class="roulette_popup_box">
          <p>Congratulations go to</p>
          <h3></h3>
          <img src="https://csi.20icipp.comundefined" alt="JAYA9-exchange" />
          <div class="roulette_popup_btnWrap">
            <div class="roulette_popup_btn disabled">Roll again</div>
          </div>
          <div class="roulette_popup_btn">OK</div>
          <div class="roulette_popup_close">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { locale, uiHelper } from '@/util'
import { RESET_WHEEL_SPIN, WHEEL_CHANCES, WHEEL_GET_MY_LOGS, WHEEL_LOGS, WHEEL_MODULE, WHEEL_SPIN } from '@/store/wheel.module'
import { SHARED } from '@/constants/constants'
import { MEMBER_DETAIL } from '@/store/member.module'

export default {
  name: 'MobileWheelOfFortune',
  data: () => ({
    currentLanguage: uiHelper.getLanguage(),
    coinRequired: '',
    is_spinning: false,
    wheel_id: 0,
    winList: true,
    recordList: false,
    prizeList: [],
    win_amount: 0,
    reward_id: 0,
    degree: 0,
    arrDegree: [0, -45, -90, -135, 180, 135, 90, 45],
    stopDegree: 0,
    isWinPrize: true,
    error: ''
  }),
  created() {
    this.getMemberDetails()
    this.getWheel()
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    wheelInfo() {
      let k = this.$store.state.wheel.wheel
      return k
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    chances() {
      return this.$store.state.wheel.wheelChances.chances
    },
    wheelLogs() {
      return this.$store.state.wheel.wheelLogs
    },
    wheelMyLogs() {
      return this.$store.state.wheel.wheelMyLogs
    },
    spinResult() {
      return this.$store.state.wheel.wheelSpin.complete
    }
  },
  watch: {
    memberInfo() {
      return this.$store.state.member.info
    },
    isLoggedIn() {
      if (this.$store.state.member.isLoggedIn) {
        this.getWheel()
      }
    },
    spinResult() {
      if (this.$store.state.wheel.wheelSpin.complete) {
        if (this.$store.state.wheel.wheelSpin.success) {
          this.win_amount = this.$store.state.wheel.wheelSpin.win_amount
          this.reward_id = this.$store.state.wheel.wheelSpin.reward_id
          let get_index = this.prizeList.indexOf(this.reward_id)
          this.degree = this.arrDegree[get_index]
          this.stopDegree = this.degree + 360 * 5
          this.rotate()
        } else {
          this.error = this.$store.state.wheel.wheelSpin.error
          this.openWinPopup()
        }
      }
    },
    wheelInfo() {
      this.prizeList = []
      let k = this.$store.state.wheel.wheel
      this.wheel_id = k.id
      this.prizeList = k.event_rewards.map(x => x.id)
      if (this.currentLanguage === 'bd') {
        this.coinRequired = this.convertToBengaliNumerals(k.event_setting.coin_required)
      } else {
        this.coinRequired = k.event_setting.coin_required
      }
      this.getWheelLogs(k)
      this.getWheelChances(k)
      this.getWheelMyLogs(k)
    },
    wheelLogs() {
      return this.$store.state.wheel.wheelLogs
    },
    wheelMyLogs() {
      return this.$store.state.wheel.wheelMyLogs
    },
    chances() {
      return this.$store.state.wheel.wheelChances.chances
    }
  },
  methods: {
    convertToBengaliNumerals(number) {
      const bengaliNumerals = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
      return number
        .toString()
        .split('')
        .map(digit => bengaliNumerals[parseInt(digit)])
        .join('')
    },
    getMemberDetails() {
      this.$store.dispatch(`${MEMBER_DETAIL}`)
    },
    closeWinDialog() {
      this.$parent.closePageDialog()
      this.resetWheel()
      this.is_spinning = false
    },
    openWinPopup() {
      let dialog = this.$parent.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.wofv3`)

      if (this.error != '') {
        dialog.message.push(this.error)

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: () => {
            this.closeWinDialog()
          }
        })
      } else if (this.win_amount == 0) {
        dialog.message.push(locale.getMessage(`message.congratsZero`, [this.$options.filters.currency(this.win_amount, 'BDT')]))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => {
            this.closeWinDialog()
          }
        })
      } else {
        dialog.message.push(locale.getMessage(`message.congrats`, [this.$options.filters.currency(this.win_amount, 'BDT')]))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => {
            this.closeWinDialog()
          }
        })
      }
      dialog.dialogXButton = null
      this.$parent.openPageDialog(dialog)
    },
    rotate() {
      $('.roulette_main_part').css('transition', 'all 0.5s ease;')
      $('.roulette_main_part').css('transform', 'rotate(' + this.degree + 'deg)')
      let func = this
      var timer = setTimeout(function() {
        if (func.degree >= func.stopDegree) {
          window.clearTimeout(timer)
          setTimeout(function() {
            func.openWinPopup()
          }, 1000)
        } else {
          func.degree += 20
          window.requestAnimationFrame(func.rotate())
        }
      }, 1)
    },
    showWinnerList() {
      this.winList = true
      this.recordList = false
    },
    showRecordList() {
      this.recordList = true
      this.winList = false
    },
    resetWheel() {
      let filter = {
        id: this.wheel_id
      }
      this.$store.dispatch(`${RESET_WHEEL_SPIN}`, { filter })
      this.$store.dispatch(`${WHEEL_CHANCES}`, { filter })
      this.getMemberDetails()
      let reset = 0
      $('.roulette_main_part').css('transition', 'all 0.5s ease;')
      $('.roulette_main_part').css('transform', 'rotate(' + reset + 'deg)')
    },
    getWheelLogs(k) {
      let filter = {
        id: k.id
      }
      this.$store.dispatch(`${WHEEL_LOGS}`, { filter })
    },
    getWheelChances(k) {
      let filter = {
        id: k.id
      }
      this.$store.dispatch(`${WHEEL_CHANCES}`, { filter })
    },
    getWheelMyLogs(k) {
      let filter = {
        id: k.id
      }
      this.$store.dispatch(`${WHEEL_GET_MY_LOGS}`, { filter })
    },
    getWheel() {
      let currency = uiHelper.getCurrency()
      let filter = {
        currency: currency
      }
      this.$store.dispatch(`${WHEEL_MODULE}`, { filter })
    },
    spinWheel() {
      if (!this.is_spinning) {
        this.is_spinning = true
        let filter = {
          id: this.wheel_id
        }
        this.$store.dispatch(`${WHEEL_SPIN}`, { filter })
      }
    }
  },
  async mounted() {
    let func = this
    let degree = 270
    let arrDegree = [0, -45, -90, -135, 180, 135, 90, 45]
    let stopDegree = 270 + 360 * 6

    /**
     * Created by Administrator on 2017/7/5.
     * 红包雨
     */
    function rotate() {
      // $('.rouletteMain_box_part').css('-webkit-transform', 'rotate(' + degree + 'deg)');
      // var timer = setTimeout(function () {
      //   if (degree >= stopDegree) {
      //     window.clearTimeout(timer);
      //   } else {
      //     degree += 5;
      //     rotate();
      //   }
      //
      // }, 1)
    }

    $(document).ready(function() {
      $('.roulette_main_btn').on('click', function() {
        if (!func.isLoggedIn) {
          func.$parent.openLoginDialog()
          return
        }
        func.spinWheel()
      })
    })
  }
}
</script>

<style scoped>
.leftMost {
  left: 0% !important;
}

.fiftyLeft {
  left: 50% !important;
}

@-webkit-keyframes sk-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-moz-keyframes sk-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-ms-keyframes sk-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes sk-fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.sk-fade-in {
  -webkit-animation: sk-fade-in 2s;
  -moz-animation: sk-fade-in 2s;
  -o-animation: sk-fade-in 2s;
  -ms-animation: sk-fade-in 2s;
}

.sk-chasing-dots {
  -webkit-animation: sk-rotate 2s linear infinite;
  animation: sk-rotate 2s linear infinite;
  height: 27px;
  position: relative;
  width: 27px;
}

.sk-dot1,
.sk-dot2 {
  -webkit-animation: sk-bounce 2s ease-in-out infinite;
  animation: sk-bounce 2s ease-in-out infinite;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  height: 60%;
  position: absolute;
  top: 0;
  width: 60%;
}

.sk-dot2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  bottom: 0;
  top: auto;
}

@-webkit-keyframes sk-rotate {
  to {
    -webkit-transform: rotate(1turn);
  }
}

@keyframes sk-rotate {
  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  to {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  to {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.sk-circle-wrapper {
  height: 22px;
  position: relative;
  width: 22px;
}

.sk-circle {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.sk-circle:before {
  -webkit-animation: sk-bouncedelay 1.2s ease-in-out infinite;
  animation: sk-bouncedelay 1.2s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background-color: #333;
  border-radius: 100%;
  content: '';
  display: block;
  height: 20%;
  margin: 0 auto;
  width: 20%;
}

.sk-circle2 {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle3 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle4 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle5 {
  -webkit-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle6 {
  -webkit-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle7 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle8 {
  -webkit-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle9 {
  -webkit-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle10 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle11 {
  -webkit-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle12 {
  -webkit-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.sk-cube-grid {
  height: 27px;
  width: 27px;
}

.sk-cube {
  -webkit-animation: sk-scaleDelay 1.3s ease-in-out infinite;
  animation: sk-scaleDelay 1.3s ease-in-out infinite;
  background: #333;
  float: left;
  height: 33%;
  width: 33%;
}

.sk-spinner .sk-cube:first-child {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-spinner .sk-cube:nth-child(2) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-spinner .sk-cube:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-spinner .sk-cube:nth-child(4) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-spinner .sk-cube:nth-child(5) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-spinner .sk-cube:nth-child(6) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-spinner .sk-cube:nth-child(7) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-spinner .sk-cube:nth-child(8) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-spinner .sk-cube:nth-child(9) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-scaleDelay {
  0%,
  70%,
  to {
    -webkit-transform: scaleX(1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-scaleDelay {
  0%,
  70%,
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  35% {
    -webkit-transform: scaleX(1);
    transform: scale3D(0, 0, 1);
  }
}

.sk-double-bounce {
  height: 27px;
  position: relative;
  width: 27px;
}

.sk-double-bounce1,
.sk-double-bounce2 {
  -webkit-animation: sk-bounce 2s ease-in-out infinite;
  animation: sk-bounce 2s ease-in-out infinite;
  background-color: #333;
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

.sk-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  to {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  to {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.sk-folding-cube {
  height: 27px;
  position: relative;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 27px;
}

.sk-folding-cube .sk-cube {
  float: left;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  width: 50%;
}

.sk-folding-cube .sk-cube:before {
  -webkit-animation: sk-foldCubeAngle 2.4s linear infinite both;
  animation: sk-foldCubeAngle 2.4s linear infinite both;
  background-color: #333;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  width: 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotate(90deg);
  transform: scale(1.1) rotate(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotate(180deg);
  transform: scale(1.1) rotate(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotate(270deg);
  transform: scale(1.1) rotate(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
  }

  25%,
  75% {
    opacity: 1;
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
  }

  90%,
  to {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
  }

  25%,
  75% {
    opacity: 1;
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
  }

  90%,
  to {
    opacity: 0;
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
  }
}

.sk-pulse {
  -webkit-animation: sk-scaleout 1s ease-in-out infinite;
  animation: sk-scaleout 1s ease-in-out infinite;
  background-color: #333;
  border-radius: 100%;
  height: 27px;
  width: 27px;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(1);
  }
}

@keyframes sk-scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.sk-rotating-plane {
  -webkit-animation: sk-rotateplane 1.2s ease-in-out infinite;
  animation: sk-rotateplane 1.2s ease-in-out infinite;
  background-color: #333;
  height: 27px;
  width: 27px;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }

  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }

  to {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }

  to {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.sk-three-bounce > div {
  -webkit-animation: sk-bouncedelay 1.4s ease-in-out infinite;
  animation: sk-bouncedelay 1.4s ease-in-out infinite;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  height: 18px;
  width: 18px;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.sk-wandering-cubes {
  height: 27px;
  position: relative;
  width: 27px;
}

.sk-cube1,
.sk-cube2 {
  -webkit-animation: sk-cubemove 1.8s ease-in-out infinite;
  animation: sk-cubemove 1.8s ease-in-out infinite;
  background-color: #333;
  height: 10px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
}

.sk-cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(22px) rotate(-90deg) scale(0.5);
  }

  50% {
    -webkit-transform: translateX(22px) translateY(22px) rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0) translateY(22px) rotate(-270deg) scale(0.5);
  }

  to {
    -webkit-transform: rotate(-1turn);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }

  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }

  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
  }

  to {
    transform: rotate(-1turn);
    -webkit-transform: rotate(-1turn);
  }
}

.sk-wave {
  height: 27px;
  width: 50px;
}

.sk-wave > div {
  -webkit-animation: sk-stretchdelay 1.2s ease-in-out infinite;
  animation: sk-stretchdelay 1.2s ease-in-out infinite;
  background-color: #333;
  display: inline-block;
  height: 100%;
  width: 6px;
}

.sk-wave .sk-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-wave .sk-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-wave .sk-rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-wave .sk-rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  to {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  to {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.sk-wordpress {
  -webkit-animation: sk-inner-circle 1s linear infinite;
  animation: sk-inner-circle 1s linear infinite;
  background: #333;
  border-radius: 27px;
  display: inline-block;
  height: 27px;
  position: relative;
  width: 27px;
}

.sk-inner-circle {
  background: #fff;
  border-radius: 8px;
  display: block;
  height: 8px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 8px;
}

@-webkit-keyframes sk-inner-circle {
  0% {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(1turn);
  }
}

@keyframes sk-inner-circle {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
  }
}

.BLACK .redBagLottery:before,
.BLACK .redBagLottery__content > div:after,
.BLACK .redBagLottery__wrap:after,
.BLACK .redBagLottery__wrap:before,
.BLACK .redBanner__fixed__content:after,
.BLACK .redBanner__fixed__imgBox:after,
.BLACK .redBanner__fixed__imgBox:before,
.BLACK .ruleActivity:before,
.RED .contentWrap__btn:after,
.redBagLottery:before,
.redBagLottery__content:after,
.redBagLottery__content:before,
.redBagLottery__content > p:after,
.redBagLottery__content > p:before,
.redBanner:after {
  content: '';
  pointer-events: none;
  position: absolute;
}

.BLACK .redBanner__fixed__content:after,
.BLACK .redBanner__fixed__imgBox:after,
.BLACK .redBanner__fixed__imgBox:before,
.BLACK .ruleActivity:before,
.redBanner:after,
.redBanner__fixed {
  background-size: cover;
  height: 0;
  left: 0;
  width: 100%;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__DRT,
.RED .getRedBag__box__ULT,
.RED .getRedBag__box__URT,
.RED .getRedBag__wrap__DLT,
.RED .getRedBag__wrap__DRT,
.RED .getRedBag__wrap__ULT,
.RED .getRedBag__wrap__URT {
  height: 17px;
  position: absolute;
  width: 17px;
}

@keyframes banner_fixed-activeBLACK {
  0% {
    opacity: 0.4;
    transform: scale3d(0.4, 0.4, 1);
  }

  to {
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes banner_fixed-activeRED {
  0% {
    opacity: 0.4;
    transform: translate3d(-50%, 0, 0) scale3d(0.4, 0.4, 1);
  }

  to {
    opacity: 1;
    transform: translate3d(-50%, 0, 0) scaleX(1);
  }
}

@keyframes lottery_money-moveBLACK {
  0% {
    transform: translateZ(0);
  }

  35% {
    transform: translate3d(3px, 0, 0);
  }

  65% {
    transform: translate3d(-3px, 0, 0);
  }

  to {
    transform: translate3d(9px, 0, 0);
  }
}

@keyframes lottery_money-moveRED {
  0%,
  to {
    transform: translate3d(3px, -3px, 0);
  }

  35% {
    transform: translate3d(-6px, -6px, 0);
  }

  65% {
    transform: translate3d(-9px, 9px, 0);
  }
}

@keyframes lottery_star-flash {
  0% {
    opacity: 0;
    transform: rotate(45deg);
  }

  16.665% {
    opacity: 1;
    transform: rotate(79deg);
  }

  49.995% {
    opacity: 1;
    transform: rotate(146deg);
  }

  66.66%,
  to {
    opacity: 0;
    transform: rotate(180deg);
  }
}

@keyframes wobble {
  0%,
  to {
    transform: translateZ(0);
  }

  7.5% {
    transform: translate3d(-25%, 0, 0) rotate(-5deg);
  }

  15% {
    transform: translate3d(20%, 0, 0) rotate(3deg);
  }

  22.5% {
    transform: translate3d(-15%, 0, 0) rotate(-3deg);
  }

  30% {
    transform: translate3d(10%, 0, 0) rotate(2deg);
  }

  37.5% {
    transform: translate3d(-5%, 0, 0) rotate(-1deg);
  }
}

@keyframes redBannerGirlBoy {
  0% {
    transform: translateZ(0);
  }

  20% {
    transform: translate3d(-5%, 0, 0) rotate(-5deg);
  }

  40% {
    transform: translate3d(4%, 0, 0) rotate(3deg);
  }

  60% {
    transform: translate3d(-3%, 0, 0) rotate(-3deg);
  }

  80% {
    transform: translate3d(2%, 0, 0) rotate(2deg);
  }

  to {
    transform: translate3d(-1%, 0, 0) rotate(-1deg);
  }
}

@keyframes redBagLottery_coin {
  0% {
    transform: translate3d(-50%, -50%, 0);
  }

  20% {
    transform: translate3d(-55%, -50%, 0) rotate(-5deg);
  }

  40% {
    transform: translate3d(-46%, -50%, 0) rotate(3deg);
  }

  60% {
    transform: translate3d(-53%, -50%, 0) rotate(-3deg);
  }

  80% {
    transform: translate3d(-48%, -50%, 0) rotate(2deg);
  }

  to {
    transform: translate3d(-51%, -50%, 0) rotate(-1deg);
  }
}

@keyframes secondHandRotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes jackInTheBox {
  0% {
    opacity: 0.75;
    transform: scale(0.8) rotate(7deg);
  }

  50% {
    transform: scale(0.9) rotate(-5deg);
  }

  75% {
    transform: scale(0.95) rotate(1deg);
  }

  to {
    opacity: 1;
    transform: scale(1) rotate(-2deg);
  }
}

@keyframes swing {
  0% {
    transform: rotate(1deg);
  }

  25% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(-1deg);
  }

  75% {
    transform: rotate(-3deg);
  }

  to {
    transform: rotate(-2deg);
  }
}

.red-envelope {
  background-color: #750f0f;
  font-size: 14px;
  line-height: 1.8;
  position: relative;
}

.red-envelope .app-body {
  overflow: hidden;
}

.contentWrap,
.redBagLottery,
.redBanner,
.redFoot,
.redHead {
  min-width: 320px;
}

.en-US.BLACK .getRedBag__box__list__scroll__item {
  font-size: 14px;
}

.en-US .getRedBag__box__list__scroll__item {
  word-spacing: 8px;
}

.zh-CN.RED .redBanner__fixed__content--expired > .redBanner__title,
.zh-CN .redBanner__fixed__content--none > .redBanner__title {
  font-size: 28px;
  line-height: 1.3;
  padding-top: 10px;
}

.zh-CN .getRedBag__box__list__scroll__item {
  padding-left: 12px;
}

.zh-CN.BLACK .redBanner__fixed__content {
  letter-spacing: 2px;
}

.zh-CN.BLACK .redBanner__fixed__content--expired > .redBanner__title {
  padding: 0 13%;
}

.zh-CN.BLACK .redBanner__title {
  padding: 0 16%;
}

.zh-CN.RED .getRedBag__box__list__scroll__item > b {
  margin-right: 6px;
}

.zh-CN.RED .getRedBag__box__list__scroll__item > span {
  margin: 0 8px;
}

.redHead {
  background-color: #ddd;
  height: 92px;
  max-width: 568px;
  position: relative;
  z-index: 40;
}

.redHead,
.redHead__wrap {
  margin: 0 auto;
}

.redHead__logo {
  display: flex;
  height: 60px;
}

.redHead__logo__img {
  height: 60%;
  margin: auto;
}

.redHead__menu {
  background-color: #f5f5f5;
  display: flex;
}

.redHead__menu__item {
  flex: 1 0 25%;
  line-height: 32px;
  text-align: center;
}

.redHead__menu__item > a {
  color: #232323;
}

.redHead__menu__item--winQuery {
  background-color: rgba(0, 0, 0, 0.5);
}

.redHead__menu__item--winQuery > a {
  color: #fff;
}

.redBanner {
  background-color: #921f24;
  height: 0;
  margin: 0 auto;
  max-width: 568px;
  padding-bottom: 91.2%;
  position: relative;
}

.redBanner .redFall {
  display: block;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 10;
}

.redBanner .redFall.fall-3 {
  animation: refall 3.5s linear infinite;
  background: url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) -10px 30px/95px 95px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 280px 10px/45px 45px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 250px 120px/75px 75px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 150px 70px/65px 65px no-repeat;
}

.redBanner .redFall.fall-2 {
  animation: refall2 9.5s linear infinite;
  background: url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 320px 500px/85px 85px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 80px 0/35px 35px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 260px 260px/55px 55px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 30px 60px/45px 45px no-repeat;
}

.redBanner .redFall.fall-1 {
  animation: refall2 6s linear infinite;
  background: url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 220px 400px/65px 65px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 180px 550px/45px 45px no-repeat,
    url(https://csi.20icipp.com/img/static/red-envelope/footer__redBag--m.png) 160px 10px/55px 55px no-repeat;
}

@keyframes refall {
  0% {
    transform: translateY(-200px);
  }

  to {
    transform: translateY(110vh);
  }
}

@keyframes refall2 {
  0% {
    transform: translateY(-600px);
  }

  to {
    transform: translateY(110vh);
  }
}

.redBanner:after {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/banner__bottom--m.png);
  bottom: 0;
  padding-bottom: 6.7632850242%;
}

.redBanner__fixed {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerbg--m.jpg);
  background-position: top;
  padding-bottom: calc(91.2% + 92px);
  position: fixed;
  top: 0;
}

.redBanner__fixed.nonfixed {
  position: absolute;
  top: -90px;
}

.redBanner__fixed__content {
  bottom: 15px;
  height: 92px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 230px;
}

.redBanner__fixed__content--none {
  animation: banner_fixed-activeRED 0.4s;
}

.redBanner__fixed__content--none > .redBanner__title {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(120deg, #831f20, #c8333a 50%, #781c1c);
  font-size: 26px;
  line-height: 1.2;
}

.redBanner__title {
  font-weight: 700;
  text-align: center;
  transform: skew(-3deg);
}

.contentWrap {
  background-image: linear-gradient(180deg, #750f0f, #b02e31);
  margin: 0 auto;
  max-width: 568px;
  padding: 0 10px 230px;
  position: relative;
}

.sectionWrap__title {
  color: #f9dfc7;
  font-size: 20px;
  line-height: 30px;
  padding: 20px 0;
  text-align: center;
}

.sectionWrap__title__deco {
  font-weight: lighter;
}

.getRedBag__wrap {
  background-color: #c8333a;
  border-radius: 12px;
}

.getRedBag__box__list__box {
  overflow: hidden;
}

.getRedBag__box__list__scroll {
  height: 96px;
  position: relative;
  top: 0;
  transition: top 0.5s;
}

.getRedBag__box__list__scroll__item {
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  padding-left: 20px;
}

.getRedBag__box__list__scroll__item > b {
  border-radius: 25px;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 18px;
  margin-right: 12px;
  text-align: center;
  width: 22px;
}

.getRedBag__box__list__scroll__item > span {
  margin: 0 12px;
}

.ruleActivity .nrc-editor-view-mode {
  counter-reset: p;
}

.ruleActivity .nrc-editor-view-mode > p {
  line-height: 26px;
  margin-bottom: 6px;
  padding-left: 30px;
  position: relative;
}

.ruleActivity .nrc-editor-view-mode > p:before {
  background-color: #f9dfc7;
  border-radius: 50%;
  color: #781c1c;
  content: counter(p);
  counter-increment: p;
  font-weight: 700;
  height: 20px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 3px;
  width: 20px;
}

.ruleActivity .nrc-editor-view-mode img {
  margin: 12px 0;
  width: 100%;
}

.redFoot {
  background-color: #781c1c;
  border-top: none;
  color: #c8333a;
  font-size: 14px;
  margin: 0 auto;
  max-width: 568px;
  position: relative;
  z-index: 30;
}

.redFoot__redBag {
  align-items: center;
  display: flex;
  height: 166px;
  justify-content: center;
  left: 50%;
  margin: -125px 0 0 -85px;
  position: absolute;
  top: 0;
  width: 171px;
}

.redFoot__redBag img {
  display: block;
  filter: drop-shadow(0 2px 7px rgba(0, 0, 0, 0.2));
  max-height: 95%;
  max-width: 95%;
}

.redBagLottery {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 35;
}

.redBagLottery:before {
  left: 0;
  top: 0;
}

.redBagLottery__wrap {
  animation: banner_fixed-active 0.4s;
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.redBagLottery__content {
  font-size: 18px;
  font-weight: 700;
  position: relative;
  text-align: center;
  width: 100%;
}

.redBagLottery__content > div {
  font-size: 30px;
}

.redBagLottery__content__btn {
  border-radius: 50px;
  color: #fff;
  margin: 0 auto;
}

.redBagLottery__close {
  border: 1px solid #fff;
  border-radius: 50%;
  bottom: 30px;
  height: 32px;
  left: 50%;
  margin: 0 auto 0 -16px;
  position: relative;
  position: absolute;
  transition: transform 0.3s;
  width: 32px;
}

.redBagLottery__close:after,
.redBagLottery__close:before {
  background-color: #fff;
  bottom: 0;
  content: '';
  height: 1px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 22px;
}

.redBagLottery__close:before {
  transform: rotate(45deg);
}

.redBagLottery__close:after {
  transform: rotate(135deg);
}

.redBagLottery__close:active {
  box-shadow: 0 -1px 2px #000;
  transform: rotate(180deg);
}

.RED .redHead__menu__item--winQuery {
  background-image: linear-gradient(180deg, #c8333a, #d15c2f);
}

.RED .redBanner__fixed__img {
  left: 50%;
  position: absolute;
  top: 31%;
  transform: translate(-50%);
  width: 86%;
}

.RED .redBanner__fixed__content {
  color: #781c1c;
}

.RED .redBanner__fixed__content--expired,
.RED .redBanner__fixed__content--in_progress,
.RED .redBanner__fixed__content--upcoming {
  animation: banner_fixed-activeRED 0.4s;
}

.RED .redBanner__fixed__content--expired > .redBanner__title {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(120deg, #831f20, #c8333a 50%, #781c1c);
  font-size: 26px;
  line-height: 1.2;
}

.RED .redBanner__fixed__content--in_progress > .redBanner__title,
.RED .redBanner__fixed__content--upcoming > .redBanner__title {
  font-size: 16px;
  line-height: 22px;
}

.RED .redBanner__fixed__content--in_progress > .redBanner__title {
  margin: 4px 0 5px;
}

.RED .redBanner__fixed__content--upcoming > .redBanner__title {
  margin: 8px 22px;
}

.RED .redBanner__fixed__content--upcoming > .redBanner__title:after,
.RED .redBanner__fixed__content--upcoming > .redBanner__title:before {
  content: '';
  height: 1px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 20%;
}

.RED .redBanner__fixed__content--upcoming > .redBanner__title:before {
  background-image: linear-gradient(270deg, #781c1c, rgba(120, 28, 28, 0));
  left: 0;
}

.RED .redBanner__fixed__content--upcoming > .redBanner__title:after {
  background-image: linear-gradient(90deg, #781c1c, rgba(120, 28, 28, 0));
  right: 0;
}

.RED .redBanner__title {
  font-weight: 700;
  text-align: center;
  transform: skew(-3deg);
  white-space: nowrap;
}

.RED .redBanner__timeBox {
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid #e5bb93;
  border-radius: 8px;
  box-shadow: inset 0 -1px 1px 0 rgba(255, 255, 255, 0.3), inset 0 1px 2px 0 rgba(198, 128, 85, 0.3);
  color: #c8333a;
  display: flex;
  font-family: Times;
  font-size: 30px;
  font-weight: 700;
  height: 38px;
  justify-content: center;
  line-height: 38px;
  margin: 0 22px;
}

.RED .redBanner__timeBox__timeWord + .redBanner__timeBox__timeWord:before {
  content: ':';
}

.RED .redBanner__timeUnit {
  display: flex;
  font-weight: 700;
  justify-content: space-between;
  line-height: 22px;
  margin: 0 22px;
  padding: 0 22px;
}

.RED .contentWrap {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/contentWrapBg--m.png), linear-gradient(180deg, #750f0f, #b02e31);
  background-position: right 10%, 0 0;
  background-repeat: no-repeat;
}

.RED .contentWrap__btn {
  background-image: linear-gradient(250deg, #894f2d, #f5dd79 40%, #c37c29);
  border-radius: 25px;
  box-shadow: 0 1px 0 #141414, 0 2px 0 #141414, 0 3px 0 #141414, 0 4px 0 #141414, 0 3px 6px 2px rgba(0, 0, 0, 0.3);
  height: 40px;
  margin: 0 auto;
  padding: 2px;
  position: relative;
  top: -15px;
  transition: top 0.4s, box-shadow 0.4s;
  width: 220px;
  z-index: 30;
}

.RED .contentWrap__btn:active {
  box-shadow: 0 0 0 #141414, 0 0 0 #141414, 0 0 0 #141414, 0 0 0 #141414, 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  top: -11px;
}

.RED .contentWrap__btn:before {
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.3), hsla(0, 0%, 100%, 0));
  border-radius: 20px;
  content: '';
  height: 18px;
  left: 10px;
  position: absolute;
  top: 5px;
  width: 200px;
}

.RED .contentWrap__btn:after {
  animation: lottery_star-flash 3s linear infinite;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__star.png);
  background-size: cover;
  height: 50px;
  left: -14px;
  top: -14px;
  transform: rotate(-15deg);
  width: 50px;
  z-index: 1;
}

.RED .contentWrap__btn__content {
  background-image: linear-gradient(180deg, #646464, #141414);
  border-radius: 25px;
  color: #ffc733;
  display: block;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
}

.RED .contentWrap__btn__content > span {
  display: block;
  transform: skew(-4deg);
}

.RED .sectionWrap__title {
  position: relative;
}

.RED .sectionWrap__title:after,
.RED .sectionWrap__title:before {
  content: '';
  height: 1px;
  margin-top: -1.5px;
  position: absolute;
  top: 50%;
  width: 26.5%;
}

.RED .sectionWrap__title:before {
  background-image: linear-gradient(270deg, #f9dfc7, hsla(29, 81%, 88%, 0));
  left: 0;
}

.RED .sectionWrap__title:after {
  background-image: linear-gradient(90deg, #f9dfc7, hsla(29, 81%, 88%, 0));
  right: 0;
}

.RED .sectionWrap__title__deco {
  display: block;
  margin: 0 auto;
  position: relative;
  width: 164px;
}

.RED .sectionWrap__title__deco:after,
.RED .sectionWrap__title__deco:before {
  background-color: #f9dfc7;
  content: '';
  height: 10px;
  margin-top: -6px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 10px;
}

.RED .sectionWrap__title__deco:before {
  left: -10px;
}

.RED .sectionWrap__title__deco:after {
  right: -10px;
}

.RED .getRedBag__wrap {
  background-image: linear-gradient(180deg, #fd6161, #8f1919);
  box-shadow: 0 3px 14px 10px rgba(53, 7, 7, 0.22);
  padding: 8px;
  position: relative;
}

.RED .getRedBag__wrap:before {
  background-image: linear-gradient(180deg, #a92c2d, #a02a2c);
  border-radius: 11px;
  content: '';
  height: calc(100% - 2px);
  left: 1px;
  position: absolute;
  top: 1px;
  width: calc(100% - 2px);
}

.RED .getRedBag__wrap__ULT,
.RED .getRedBag__wrap__URT {
  background-color: #a92c2d;
  top: 8px;
}

.RED .getRedBag__wrap__DLT,
.RED .getRedBag__wrap__DRT {
  background-color: #a02a2c;
  bottom: 8px;
}

.RED .getRedBag__wrap__DLT,
.RED .getRedBag__wrap__ULT {
  left: 8px;
}

.RED .getRedBag__wrap__DRT,
.RED .getRedBag__wrap__URT {
  right: 8px;
}

.RED .getRedBag__wrap__ULT {
  border-bottom-right-radius: 20px;
  box-shadow: 1px 2px 1px rgba(255, 255, 255, 0.5);
}

.RED .getRedBag__wrap__URT {
  border-bottom-left-radius: 20px;
  box-shadow: -1px 2px 1px rgba(255, 255, 255, 0.5);
}

.RED .getRedBag__wrap__DLT {
  border-top-right-radius: 20px;
}

.RED .getRedBag__wrap__DRT {
  border-top-left-radius: 20px;
}

.RED .getRedBag__box {
  background-image: linear-gradient(270deg, #e5bb92, #fbe3cc 51%, #e5bb92);
  box-shadow: inset 0 2px 1px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 6px;
  position: relative;
}

.RED .getRedBag__box__list {
  background-image: linear-gradient(180deg, #781c1c, #320d0d);
  padding: 15px 1px;
  position: relative;
}

.RED .getRedBag__box__list:before {
  background-image: linear-gradient(270deg, #e5bb92, #efcdac 8%, #fbe3cc 51%, #edc9a7 91%, #e5bb92);
  content: '';
  height: calc(100% - 2px);
  left: 1px;
  position: absolute;
  top: 1px;
  width: calc(100% - 2px);
}

.RED .getRedBag__box__list__scroll__item {
  color: #781c1c;
}

.RED .getRedBag__box__list__scroll__item > b {
  background-color: #781c1c;
  color: #f9dfc7;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__DRT,
.RED .getRedBag__box__ULT,
.RED .getRedBag__box__URT {
  background-color: #e5bb92;
  border-style: solid;
  box-sizing: border-box;
}

.RED .getRedBag__box__ULT,
.RED .getRedBag__box__URT {
  top: 6px;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__DRT {
  bottom: 6px;
}

.RED .getRedBag__box__DLT,
.RED .getRedBag__box__ULT {
  left: 6px;
}

.RED .getRedBag__box__DRT,
.RED .getRedBag__box__URT {
  right: 6px;
}

.RED .getRedBag__box__ULT {
  border-bottom-right-radius: 20px;
  border-color: transparent #781c1c #781c1c transparent;
  border-width: 0 1px 1px 0;
}

.RED .getRedBag__box__URT {
  border-bottom-left-radius: 20px;
  border-color: transparent transparent #781c1c #781c1c;
  border-width: 0 0 1px 1px;
}

.RED .getRedBag__box__DLT {
  border-color: #320d0d #320d0d transparent transparent;
  border-top-right-radius: 20px;
  border-width: 1px 1px 0 0;
}

.RED .getRedBag__box__DRT {
  border-color: #320d0d transparent transparent #320d0d;
  border-top-left-radius: 20px;
  border-width: 1px 0 0 1px;
}

.RED .ruleActivity .nrc-editor-view-mode {
  color: #f9dfc7;
  padding: 0 10px;
}

.RED .ruleActivity .nrc-editor-view-mode > p {
  line-height: 26px;
}

.RED .ruleActivity .nrc-editor-view-mode > p:before {
  background-color: #f9dfc7;
  color: #781c1c;
  height: 20px;
  line-height: 20px;
  width: 20px;
}

.RED .redFoot {
  margin-top: -49px;
}

.RED .redFoot__redBag {
  animation: wobble 2s infinite;
}

.RED .redBagLottery {
  background-color: rgba(69, 16, 16, 0.8);
}

.RED .redBagLottery:before {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
  width: 100%;
}

.RED .redBagLottery__wrap {
  min-height: 325px;
  width: 260px;
}

.RED .redBagLottery__content {
  background-color: #fff;
  border-radius: 14px;
  color: #781c1c;
  left: 50%;
  min-height: 272px;
  padding: 90px 10px 20px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, calc(-50% + 50px));
}

.RED .redBagLottery__content:after,
.RED .redBagLottery__content:before {
  background-size: cover;
}

.RED .redBagLottery__content:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__head--m.png);
  height: 159px;
  left: 0;
  top: -68px;
  width: 100%;
}

.RED .redBagLottery__content:after {
  animation: lottery_money-moveRED 7s linear infinite;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__money--m.png);
  height: 182px;
  left: -46px;
  top: -73px;
  width: 362px;
}

.RED .redBagLottery__content > p {
  margin-top: 22px;
}

.RED .redBagLottery__content > p:after {
  animation: lottery_star-flash 3s linear infinite;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__star--m.png);
  background-size: cover;
  height: 80px;
  left: 54px;
  top: -34px;
  transform: rotate(-15deg);
  width: 80px;
  z-index: 1;
}

.RED .redBagLottery__content > div {
  line-height: 45px;
  margin-bottom: 22px;
}

.RED .redBagLottery__content__btn {
  background-image: linear-gradient(180deg, #e6454b, #c9353a);
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  width: 188px;
}

.BLACK.red-envelope {
  color: #fff;
  overflow-x: hidden;
}

.BLACK .redHead__menu {
  background-color: #f2f2f2;
}

.BLACK .redHead__menu__item > a {
  color: #000;
}

.BLACK .redHead__menu__item--winQuery {
  background-image: linear-gradient(180deg, #502daa, #7541d1 50%);
}

.BLACK .redHead__menu__item--winQuery > a {
  color: #fff;
}

.BLACK .redBanner {
  background-image: linear-gradient(180deg, #502daa 8%, #682691 47%, #d6081f);
  padding-bottom: 133.3333333333%;
}

.BLACK .redBanner:after {
  animation: jackInTheBox 24s infinite alternate;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerFixed__rain--m.png);
  height: 100%;
  padding: 32px 0 0;
  transform-origin: center bottom;
}

.BLACK .redBanner__fixed {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/contentWrap__top--m.png), linear-gradient(180deg, transparent 26.5%, #c80c2d 0, #b61140);
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  height: 44%;
  padding-bottom: 0;
  position: absolute;
  top: auto;
}

.BLACK .redBanner__fixed__imgBox {
  background: url(https://csi.20icipp.com/img/static/red-envelope/bannerFixedBg--m.png) no-repeat 0 68px / contain;
  height: 0;
  left: 0;
  padding-bottom: calc(61.33333% + 92px);
  position: fixed;
  top: 0;
  width: 100%;
}

.BLACK .redBanner__fixed__imgBox:after,
.BLACK .redBanner__fixed__imgBox:before {
  animation: redBannerGirlBoy 18s linear infinite alternate;
  padding-bottom: 66.6666666667%;
}

.BLACK .redBanner__fixed__imgBox:before {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/redBanner__boy--m.png);
  top: 92px;
}

.BLACK .redBanner__fixed__imgBox:after {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/redBanner__girl--m.png);
  top: calc(12.68116% + 92px);
}

.BLACK .redBanner__fixed__img {
  display: block;
  margin: calc(20% + 92px) auto 0;
  width: 54%;
}

.BLACK .redBanner__fixed__content {
  background-image: linear-gradient(150deg, #b49fe5, #9171d4 25%, #754ec4 50%, #6336b3 75%, #5b2ba2);
  border: 1px solid #fff;
  border-radius: 50%;
  bottom: 14.5454545455%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3), inset 2px 2px 4px rgba(0, 0, 0, 0.3);
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 105.4545454545%;
  justify-content: center;
  line-height: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  width: 61.8666666667%;
}

.BLACK .redBanner__fixed__content:after {
  animation: secondHandRotate 60s linear infinite reverse;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/bannerFixedContent__coin--m.png);
  padding-bottom: 100%;
  top: 0;
}

.BLACK .redBanner__fixed__content--expired,
.BLACK .redBanner__fixed__content--in_progress,
.BLACK .redBanner__fixed__content--upcoming {
  animation: banner_fixed-activeBLACK 0.4s;
}

.BLACK .redBanner__fixed__content--expired > .redBanner__title {
  font-size: 21px;
  line-height: 1.65;
}

.BLACK .redBanner__fixed__content--in_progress > .redBanner__title {
  margin-top: -36px;
}

.BLACK .redBanner__title {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  padding: 0 10px;
  transform: skew(-3deg);
}

.BLACK .redBanner__timeBox {
  display: flex;
  font-family: Helvetica;
  font-size: 31px;
  height: 54px;
  justify-content: center;
  line-height: 1.6875;
  transform: scaleY(1.15);
}

.BLACK .redBanner__timeBox__timeWord + .redBanner__timeBox__timeWord:before {
  content: ':';
}

.BLACK .redBanner__timeUnit {
  display: flex;
  justify-content: space-between;
  padding: 0 14%;
}

.BLACK .redBanner__secondHand {
  animation: secondHandRotate 60s linear infinite;
  border-color: #fcebff;
  border-radius: 250px 0 0 0;
  border-style: solid;
  border-width: 5px 0 0 5px;
  box-shadow: -1px -1px 1px rgba(153, 153, 204, 0.5), inset 1px 1px 1px rgba(153, 153, 204, 0.5);
  box-sizing: content-box;
  height: 49.1379310345%;
  left: -3px;
  position: absolute;
  top: -3px;
  transform-origin: 100% 100%;
  width: 49.1379310345%;
}

.BLACK .redBanner__secondHand:after,
.BLACK .redBanner__secondHand:before {
  background-color: #fcebff;
  border-radius: 50%;
  content: '';
  height: 5px;
  position: absolute;
  width: 5px;
}

.BLACK .redBanner__secondHand:before {
  box-shadow: -2px 0 0 #fcebff, 0 0 1px 1px rgba(153, 153, 204, 0.5);
  right: -2.5px;
  top: -5px;
}

.BLACK .redBanner__secondHand:after {
  bottom: -2.5px;
  box-shadow: 0 -2px 0 #fcebff, 0 0 1px 1px rgba(153, 153, 204, 0.5);
  left: -5px;
}

.BLACK .contentWrap {
  background-image: linear-gradient(180deg, rgba(182, 17, 64, 0), #b61140 30px, #8c1d6c 24%, #722387);
  margin-top: -30px;
  padding: 30px 0 0;
}

.BLACK .contentWrap__btn {
  background-color: #b388ff;
  border-radius: 25px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25), inset 0 0 1px rgba(0, 0, 0, 0.2), inset 0 0 18px rgba(0, 0, 0, 0.2), inset 0 0 12px #7e52cc;
  font-size: 20px;
  font-weight: 700;
  height: 36px;
  left: 50%;
  line-height: 36px;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  top: -72px;
  transform: translate(-50%);
  transition: box-shadow 0.4s;
  width: 136px;
}

.BLACK .contentWrap__btn__content {
  display: block;
  transform: skew(-4deg);
}

.BLACK .contentWrap__btn:active {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25), inset 0 0 0 rgba(0, 0, 0, 0.2), inset 0 0 0 rgba(0, 0, 0, 0.2), inset 0 0 0 #7e52cc;
}

.BLACK .sectionWrap__title {
  color: #fff;
}

.BLACK .getRedBag__wrap {
  background-image: linear-gradient(90deg, #502daa, #7541d1 50%, #522da8);
  border-radius: 17px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5), inset 0 0 13px rgba(255, 255, 255, 0.2);
  margin: 0 20px 22px;
  position: relative;
  z-index: 1;
}

.BLACK .getRedBag__box {
  padding: 20px 0;
}

.BLACK .getRedBag__box__list__scroll__item > b {
  background-color: #fff;
  color: #502daa;
}

.BLACK .ruleActivity {
  padding-bottom: 50px;
  position: relative;
}

.BLACK .ruleActivity:before {
  animation: swing 8s linear infinite alternate;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/getRedBag__bg--m.png);
  padding-bottom: 21.6183574879%;
  top: -40px;
  transform-origin: top center;
}

.BLACK .ruleActivity .nrc-editor-view-mode {
  padding: 0 20px;
}

.BLACK .ruleActivity .nrc-editor-view-mode > p {
  line-height: 24px;
}

.BLACK .ruleActivity .nrc-editor-view-mode > p:before {
  background-color: #fff;
  color: #861e72;
  height: 18px;
  line-height: 18px;
  width: 18px;
}

.BLACK .redFoot {
  background-color: #502daa;
  color: #fff;
}

.BLACK .redFoot__redBag {
  display: none;
}

.BLACK .redBagLottery {
  background-image: radial-gradient(rgba(36, 26, 65, 0.85) 52%, rgba(0, 0, 0, 0.85) 100%);
}

.BLACK .redBagLottery:before {
  animation: redBagLottery_coin 6s linear infinite alternate;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__coin.png);
  background-size: cover;
  height: 0;
  left: 45%;
  padding-bottom: 109.0909090909%;
  top: 42%;
  transform: translate(-50%, -50%);
  transform-origin: top center;
  width: 100%;
}

.BLACK .redBagLottery__wrap {
  height: 402px;
  padding-top: 110px;
  width: 220px;
}

.BLACK .redBagLottery__wrap:after,
.BLACK .redBagLottery__wrap:before {
  animation: lottery_money-moveBLACK 4s linear infinite alternate;
  background-size: cover;
}

.BLACK .redBagLottery__wrap:before {
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lotteryWrap__coin.png);
  height: 124px;
  left: 20px;
  top: 8px;
  width: 150px;
  z-index: 1;
}

.BLACK .redBagLottery__wrap:after {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lotteryWrap__boy.png);
  bottom: 24px;
  height: 90px;
  left: -90px;
  width: 157px;
}

.BLACK .redBagLottery__content {
  background-image: linear-gradient(90deg, #bc0f39, #d00924 50%, #b9103d);
  border-radius: 0 0 12px 12px;
  box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.35);
  height: 230px;
  margin-bottom: 30px;
  padding-top: 60px;
}

.BLACK .redBagLottery__content:after,
.BLACK .redBagLottery__content:before {
  left: 0;
  width: 100%;
}

.BLACK .redBagLottery__content:before {
  background-image: linear-gradient(180deg, #a31140, #9f103b 25%, #9b0f37 50%, #8d0d2e 75%, #750a26);
  height: 22px;
  top: 0;
}

.BLACK .redBagLottery__content:after {
  background-image: linear-gradient(180deg, #a9154d 10%, #950924 45%);
  border-radius: 200px 200px 0 0;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.35);
  height: 110px;
  top: -95px;
  transform: scaleY(0.73);
  z-index: -1;
}

.BLACK .redBagLottery__content > p {
  line-height: 1;
}

.BLACK .redBagLottery__content > p:after,
.BLACK .redBagLottery__content > p:before {
  border-style: solid;
  border-width: 11px 8px;
  height: 0;
  top: 0;
  width: 0;
}

.BLACK .redBagLottery__content > p:after {
  border-color: transparent transparent #bc0f39 #bc0f39;
  left: 0;
}

.BLACK .redBagLottery__content > p:before {
  border-color: transparent #b9103d #b9103d transparent;
  right: 0;
}

.BLACK .redBagLottery__content > div {
  line-height: 60px;
}

.BLACK .redBagLottery__content > div:after {
  animation: lottery_star-flash 3s linear infinite;
  background-image: url(https://csi.20icipp.com/img/static/red-envelope/lottery__star--m.png);
  background-size: cover;
  height: 114px;
  left: -2px;
  top: -135px;
  width: 114px;
  z-index: 1;
}

.BLACK .redBagLottery__content__btn {
  background-image: linear-gradient(90deg, #b43269 10%, #a22846 45%);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.35), inset -1px -1px 10px 1px rgba(0, 0, 0, 0.25);
  height: 46px;
  line-height: 46px;
  transition: box-shadow 0.4s;
  width: 142px;
}

.BLACK .redBagLottery__content__btn:active {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), inset 0 0 1px 1px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 335px) {
  .redBanner__fixed {
    padding-bottom: 384px;
    width: 320px;
  }

  .getRedBag__box__list__scroll__item {
    font-size: 12px;
    padding-left: 12px;
  }

  .getRedBag__box__list__scroll__item > b {
    margin-right: 6px;
  }

  .getRedBag__box__list__scroll__item > span {
    margin: 0 6px;
  }

  .zh-CN.BLACK .redBanner__fixed__content--expired > .redBanner__title {
    padding: 0 8%;
  }

  .zh-CN.BLACK .redBanner__fixed__content--in_progress > .redBanner__title {
    padding: 0 12%;
  }

  .zh-CN.RED .getRedBag__box__list__scroll__item {
    padding-left: 8px;
  }

  .zh-CN.RED .getRedBag__box__list__scroll__item > span {
    margin: 0 6px;
  }

  .RED .contentWrap {
    padding: 0 8px 230px;
  }

  .RED .sectionWrap__title:after,
  .RED .sectionWrap__title:before {
    width: 22%;
  }

  .RED .getRedBag__box__list__scroll__item > b {
    margin-right: 10px;
  }

  .RED .getRedBag__box__list__scroll__item > span {
    margin: 0 10px;
  }

  .BLACK .redBanner__timeBox {
    font-size: 26px;
    height: 44px;
  }

  .BLACK .contentWrap__btn {
    top: -60px;
  }
}

@media screen and (min-width: 568px) {
  .red-envelope.BLACK {
    background-image: linear-gradient(180deg, #b61140, #682691 68%, #502daa);
  }

  .red-envelope:before {
    box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.25);
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%);
    width: 568px;
  }

  .redBanner {
    box-shadow: 0 0 18px 2px #781c1c;
    padding-bottom: 518px;
  }

  .RED .redBanner__fixed,
  .redBanner__fixed {
    left: 50%;
    margin-left: -284px;
    padding-bottom: 610px;
    width: 568px;
  }

  .BLACK .redBanner {
    padding-bottom: 757px;
  }

  .BLACK .redBanner__fixed__imgBox {
    left: 50%;
    margin-left: -284px;
    padding-bottom: 440px;
    width: 568px;
  }

  .BLACK .redBanner__fixed__content--expired > .redBanner__title {
    font-size: 32px;
  }

  .BLACK .contentWrap__btn {
    top: -100px;
  }
}

.red-fall {
  position: relative;
  z-index: 1;
}

.red-fall img {
  transform: scale(0.6);
}

@keyframes rouletteDecoShake {
  0% {
    transform: rotate(5deg);
  }

  33.33% {
    transform: rotate(-7.5deg);
  }

  66.66% {
    transform: rotate(11.25deg);
  }

  to {
    transform: rotate(-16.875deg);
  }
}

@keyframes rouletteSpecialAwardM {
  0% {
    box-shadow: 0 0 0 0 #f304ac, 0 0 0 #f304ac, inset 0 0 1px #f304ac;
  }

  to {
    box-shadow: 0 0 10.1333333333vw 1.0666666667vw #f304ac, 0 0 4.2666666667vw #f304ac, inset 0 0 1px #f304ac;
  }
}

@keyframes rouletteSpecialAwardM-pigSize {
  0% {
    box-shadow: 0 0 0 0 #f304ac, 0 0 0 #f304ac, inset 0 0 1px #f304ac;
  }

  to {
    box-shadow: 0 0 56px 6px #f304ac, 0 0 24px #f304ac, inset 0 0 1px #f304ac;
  }
}

@keyframes rouletteNeonLight {
  0% {
    background-position: 0 50%;
  }

  to {
    background-position: 200% 50%;
  }
}

@keyframes roulettePrizeNumberM {
  0% {
    box-shadow: inset 0 0 1px rgba(255, 207, 0, 0.75), 0 0 1px 1px rgba(255, 207, 0, 0.75), 0 0 0 0 rgba(255, 234, 0, 0), 0 0 0 0 rgba(255, 234, 0, 0);
  }

  to {
    box-shadow: inset 0 0 1px rgba(255, 207, 0, 0.75), 0 0 1px 1px rgba(255, 207, 0, 0.75), 0 0 4.8vw 0.5333333333vw rgba(255, 234, 0, 0.75), 0 0 1.6vw 0.2666666667vw rgba(255, 234, 0, 0.9);
  }
}

@keyframes roulettePrizeNumberM-pigSize {
  0% {
    box-shadow: inset 0 0 1px rgba(255, 207, 0, 0.75), 0 0 1px 1px rgba(255, 207, 0, 0.75), 0 0 0 0 rgba(255, 234, 0, 0), 0 0 0 0 rgba(255, 234, 0, 0);
  }

  to {
    box-shadow: inset 0 0 1px rgba(255, 207, 0, 0.75), 0 0 1px 1px rgba(255, 207, 0, 0.75), 0 0 28px 3px rgba(255, 234, 0, 0.75), 0 0 9px 2px rgba(255, 234, 0, 0.9);
  }
}

@keyframes roulettePrizeNumberAwardM {
  0% {
    text-shadow: 0 1px 0 #dd3f07, 0 -1px 0 #dd3f07, -1px 0 0 #dd3f07, 1px 0 0 #dd3f07, 1px 1px 0 #dd3f07, 1px -1px 0 #dd3f07, -1px -1px 0 #dd3f07, -1px 1px 0 #dd3f07, -1px -2px 0 rgba(255, 234, 0, 0),
      0 -2px 0 rgba(255, 234, 0, 0), 1px -2px 0 rgba(255, 234, 0, 0), 2px -2px 0 rgba(255, 234, 0, 0), 2px -1px 0 rgba(255, 234, 0, 0), 2px 0 0 rgba(255, 234, 0, 0), 2px 1px 0 rgba(255, 234, 0, 0),
      2px 2px 0 rgba(255, 234, 0, 0), 1px 2px 0 rgba(255, 234, 0, 0), 0 2px 0 rgba(255, 234, 0, 0), -1px 2px 0 rgba(255, 234, 0, 0), -2px 2px 0 rgba(255, 234, 0, 0), -2px 1px 0 rgba(255, 234, 0, 0),
      -2px 0 0 rgba(255, 234, 0, 0), -2px -1px 0 rgba(255, 234, 0, 0), -2px -2px 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: 0 1px 0 #dd3f07, 0 -1px 0 #dd3f07, -1px 0 0 #dd3f07, 1px 0 0 #dd3f07, 1px 1px 0 #dd3f07, 1px -1px 0 #dd3f07, -1px -1px 0 #dd3f07, -1px 1px 0 #dd3f07,
      -1px -2px 4px rgba(255, 234, 0, 0.7), 0 -2px 4px rgba(255, 234, 0, 0.7), 1px -2px 4px rgba(255, 234, 0, 0.7), 2px -2px 4px rgba(255, 234, 0, 0.7), 2px -1px 4px rgba(255, 234, 0, 0.7),
      2px 0 4px rgba(255, 234, 0, 0.7), 2px 1px 4px rgba(255, 234, 0, 0.7), 2px 2px 4px rgba(255, 234, 0, 0.7), 1px 2px 4px rgba(255, 234, 0, 0.7), 0 2px 4px rgba(255, 234, 0, 0.7),
      -1px 2px 4px rgba(255, 234, 0, 0.7), -2px 2px 4px rgba(255, 234, 0, 0.7), -2px 1px 4px rgba(255, 234, 0, 0.7), -2px 0 4px rgba(255, 234, 0, 0.7), -2px -1px 4px rgba(255, 234, 0, 0.7),
      -2px -2px 4px rgba(255, 234, 0, 0.7);
  }
}

@keyframes roulettePrizeNumber0ShadowM {
  0% {
    text-shadow: -0.8vw -1.0666666667vw 0 rgba(255, 234, 0, 0), -0.5333333333vw -1.0666666667vw 0 rgba(255, 234, 0, 0), -0.2666666667vw -1.0666666667vw 0 rgba(255, 234, 0, 0),
      0 -1.0666666667vw 0 rgba(255, 234, 0, 0), 0.2666666667vw -1.0666666667vw 0 rgba(255, 234, 0, 0), 0.5333333333vw -1.0666666667vw 0 rgba(255, 234, 0, 0),
      0.8vw -1.0666666667vw 0 rgba(255, 234, 0, 0), 1.0666666667vw -1.0666666667vw 0 rgba(255, 234, 0, 0), 1.0666666667vw -0.8vw 0 rgba(255, 234, 0, 0),
      1.0666666667vw -0.5333333333vw 0 rgba(255, 234, 0, 0), 1.0666666667vw -0.2666666667vw 0 rgba(255, 234, 0, 0), 1.0666666667vw 0 0 rgba(255, 234, 0, 0),
      1.0666666667vw 0.2666666667vw 0 rgba(255, 234, 0, 0), 1.0666666667vw 0.5333333333vw 0 rgba(255, 234, 0, 0), 1.0666666667vw 0.8vw 0 rgba(255, 234, 0, 0),
      1.0666666667vw 1.0666666667vw 0 rgba(255, 234, 0, 0), 0.8vw 1.0666666667vw 0 rgba(255, 234, 0, 0), 0.5333333333vw 1.0666666667vw 0 rgba(255, 234, 0, 0),
      0.2666666667vw 1.0666666667vw 0 rgba(255, 234, 0, 0), 0 1.0666666667vw 0 rgba(255, 234, 0, 0), -0.2666666667vw 1.0666666667vw 0 rgba(255, 234, 0, 0),
      -0.5333333333vw 1.0666666667vw 0 rgba(255, 234, 0, 0), -0.8vw 1.0666666667vw 0 rgba(255, 234, 0, 0), -1.0666666667vw 1.0666666667vw 0 rgba(255, 234, 0, 0),
      -1.0666666667vw 0.8vw 0 rgba(255, 234, 0, 0), -1.0666666667vw 0.5333333333vw 0 rgba(255, 234, 0, 0), -1.0666666667vw 0.2666666667vw 0 rgba(255, 234, 0, 0),
      -1.0666666667vw 0 0 rgba(255, 234, 0, 0), -1.0666666667vw -0.2666666667vw 0 rgba(255, 234, 0, 0), -1.0666666667vw -0.5333333333vw 0 rgba(255, 234, 0, 0),
      -1.0666666667vw -0.8vw 0 rgba(255, 234, 0, 0), -1.0666666667vw -1.0666666667vw 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -0.8vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), -0.5333333333vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      -0.2666666667vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 0 -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      0.2666666667vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 0.5333333333vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      0.8vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 1.0666666667vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 1.0666666667vw -0.8vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      1.0666666667vw -0.5333333333vw 2.1333333333vw rgba(255, 234, 0, 0.3), 1.0666666667vw -0.2666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 1.0666666667vw 0 2.1333333333vw rgba(255, 234, 0, 0.3),
      1.0666666667vw 0.2666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 1.0666666667vw 0.5333333333vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      1.0666666667vw 0.8vw 2.1333333333vw rgba(255, 234, 0, 0.3), 1.0666666667vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 0.8vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      0.5333333333vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 0.2666666667vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), 0 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      -0.2666666667vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), -0.5333333333vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      -0.8vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), -1.0666666667vw 1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3), -1.0666666667vw 0.8vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      -1.0666666667vw 0.5333333333vw 2.1333333333vw rgba(255, 234, 0, 0.3), -1.0666666667vw 0.2666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      -1.0666666667vw 0 2.1333333333vw rgba(255, 234, 0, 0.3), -1.0666666667vw -0.2666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      -1.0666666667vw -0.5333333333vw 2.1333333333vw rgba(255, 234, 0, 0.3), -1.0666666667vw -0.8vw 2.1333333333vw rgba(255, 234, 0, 0.3),
      -1.0666666667vw -1.0666666667vw 2.1333333333vw rgba(255, 234, 0, 0.3);
  }
}

@keyframes roulettePrizeNumber0ShadowM-pigSize {
  0% {
    text-shadow: -4px -5px 0 rgba(255, 234, 0, 0), -3px -5px 0 rgba(255, 234, 0, 0), -2px -5px 0 rgba(255, 234, 0, 0), -1px -5px 0 rgba(255, 234, 0, 0), 0 -5px 0 rgba(255, 234, 0, 0),
      1px -5px 0 rgba(255, 234, 0, 0), 2px -5px 0 rgba(255, 234, 0, 0), 3px -5px 0 rgba(255, 234, 0, 0), 4px -5px 0 rgba(255, 234, 0, 0), 5px -5px 0 rgba(255, 234, 0, 0),
      5px -4px 0 rgba(255, 234, 0, 0), 5px -3px 0 rgba(255, 234, 0, 0), 5px -2px 0 rgba(255, 234, 0, 0), 5px -1px 0 rgba(255, 234, 0, 0), 5px 0 0 rgba(255, 234, 0, 0), 5px 1px 0 rgba(255, 234, 0, 0),
      5px 2px 0 rgba(255, 234, 0, 0), 5px 3px 0 rgba(255, 234, 0, 0), 5px 4px 0 rgba(255, 234, 0, 0), 5px 5px 0 rgba(255, 234, 0, 0), 4px 5px 0 rgba(255, 234, 0, 0), 3px 5px 0 rgba(255, 234, 0, 0),
      2px 5px 0 rgba(255, 234, 0, 0), 1px 5px 0 rgba(255, 234, 0, 0), 0 5px 0 rgba(255, 234, 0, 0), -1px 5px 0 rgba(255, 234, 0, 0), -2px 5px 0 rgba(255, 234, 0, 0), -3px 5px 0 rgba(255, 234, 0, 0),
      -4px 5px 0 rgba(255, 234, 0, 0), -5px 5px 0 rgba(255, 234, 0, 0), -5px 4px 0 rgba(255, 234, 0, 0), -5px 3px 0 rgba(255, 234, 0, 0), -5px 2px 0 rgba(255, 234, 0, 0),
      -5px 1px 0 rgba(255, 234, 0, 0), -5px 0 0 rgba(255, 234, 0, 0), -5px -1px 0 rgba(255, 234, 0, 0), -5px -2px 0 rgba(255, 234, 0, 0), -5px -3px 0 rgba(255, 234, 0, 0),
      -5px -4px 0 rgba(255, 234, 0, 0), -5px -5px 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -4px -5px 10px rgba(255, 234, 0, 0.3), -3px -5px 10px rgba(255, 234, 0, 0.3), -2px -5px 10px rgba(255, 234, 0, 0.3), -1px -5px 10px rgba(255, 234, 0, 0.3),
      0 -5px 10px rgba(255, 234, 0, 0.3), 1px -5px 10px rgba(255, 234, 0, 0.3), 2px -5px 10px rgba(255, 234, 0, 0.3), 3px -5px 10px rgba(255, 234, 0, 0.3), 4px -5px 10px rgba(255, 234, 0, 0.3),
      5px -5px 10px rgba(255, 234, 0, 0.3), 5px -4px 10px rgba(255, 234, 0, 0.3), 5px -3px 10px rgba(255, 234, 0, 0.3), 5px -2px 10px rgba(255, 234, 0, 0.3), 5px -1px 10px rgba(255, 234, 0, 0.3),
      5px 0 10px rgba(255, 234, 0, 0.3), 5px 1px 10px rgba(255, 234, 0, 0.3), 5px 2px 10px rgba(255, 234, 0, 0.3), 5px 3px 10px rgba(255, 234, 0, 0.3), 5px 4px 10px rgba(255, 234, 0, 0.3),
      5px 5px 10px rgba(255, 234, 0, 0.3), 4px 5px 10px rgba(255, 234, 0, 0.3), 3px 5px 10px rgba(255, 234, 0, 0.3), 2px 5px 10px rgba(255, 234, 0, 0.3), 1px 5px 10px rgba(255, 234, 0, 0.3),
      0 5px 10px rgba(255, 234, 0, 0.3), -1px 5px 10px rgba(255, 234, 0, 0.3), -2px 5px 10px rgba(255, 234, 0, 0.3), -3px 5px 10px rgba(255, 234, 0, 0.3), -4px 5px 10px rgba(255, 234, 0, 0.3),
      -5px 5px 10px rgba(255, 234, 0, 0.3), -5px 4px 10px rgba(255, 234, 0, 0.3), -5px 3px 10px rgba(255, 234, 0, 0.3), -5px 2px 10px rgba(255, 234, 0, 0.3), -5px 1px 10px rgba(255, 234, 0, 0.3),
      -5px 0 10px rgba(255, 234, 0, 0.3), -5px -1px 10px rgba(255, 234, 0, 0.3), -5px -2px 10px rgba(255, 234, 0, 0.3), -5px -3px 10px rgba(255, 234, 0, 0.3), -5px -4px 10px rgba(255, 234, 0, 0.3),
      -5px -5px 10px rgba(255, 234, 0, 0.3);
  }
}

@keyframes roulettePrizeNumberTextM {
  0% {
    text-shadow: -0.5333333333vw -0.8vw 0 #dd3f07, -0.2666666667vw -0.8vw 0 #dd3f07, 0 -0.8vw 0 #dd3f07, 0.2666666667vw -0.8vw 0 #dd3f07, 0.5333333333vw -0.8vw 0 #dd3f07, 0.8vw -0.8vw 0 #dd3f07,
      0.8vw -0.5333333333vw 0 #dd3f07, 0.8vw -0.2666666667vw 0 #dd3f07, 0.8vw 0 0 #dd3f07, 0.8vw 0.2666666667vw 0 #dd3f07, 0.8vw 0.5333333333vw 0 #dd3f07, 0.8vw 0.8vw 0 #dd3f07,
      0.5333333333vw 0.8vw 0 #dd3f07, 0.2666666667vw 0.8vw 0 #dd3f07, 0 0.8vw 0 #dd3f07, -0.2666666667vw 0.8vw 0 #dd3f07, -0.5333333333vw 0.8vw 0 #dd3f07, -0.8vw 0.8vw 0 #dd3f07,
      -0.8vw 0.5333333333vw 0 #dd3f07, -0.8vw 0.2666666667vw 0 #dd3f07, -0.8vw 0 0 #dd3f07, -0.8vw -0.2666666667vw 0 #dd3f07, -0.8vw -0.5333333333vw 0 #dd3f07, -0.8vw -0.8vw 0 #dd3f07,
      -1.0666666667vw -1.3333333333vw 0 rgba(255, 234, 0, 0), -0.8vw -1.3333333333vw 0 rgba(255, 234, 0, 0), -0.5333333333vw -1.3333333333vw 0 rgba(255, 234, 0, 0),
      -0.2666666667vw -1.3333333333vw 0 rgba(255, 234, 0, 0), 0 -1.3333333333vw 0 rgba(255, 234, 0, 0), 0.2666666667vw -1.3333333333vw 0 rgba(255, 234, 0, 0),
      0.5333333333vw -1.3333333333vw 0 rgba(255, 234, 0, 0), 0.8vw -1.3333333333vw 0 rgba(255, 234, 0, 0), 1.0666666667vw -1.3333333333vw 0 rgba(255, 234, 0, 0),
      1.3333333333vw -1.3333333333vw 0 rgba(255, 234, 0, 0), 1.3333333333vw -1.0666666667vw 0 rgba(255, 234, 0, 0), 1.3333333333vw -0.8vw 0 rgba(255, 234, 0, 0),
      1.3333333333vw -0.5333333333vw 0 rgba(255, 234, 0, 0), 1.3333333333vw -0.2666666667vw 0 rgba(255, 234, 0, 0), 1.3333333333vw 0 0 rgba(255, 234, 0, 0),
      1.3333333333vw 0.2666666667vw 0 rgba(255, 234, 0, 0), 1.3333333333vw 0.5333333333vw 0 rgba(255, 234, 0, 0), 1.3333333333vw 0.8vw 0 rgba(255, 234, 0, 0),
      1.3333333333vw 1.0666666667vw 0 rgba(255, 234, 0, 0), 1.3333333333vw 1.3333333333vw 0 rgba(255, 234, 0, 0), 1.0666666667vw 1.3333333333vw 0 rgba(255, 234, 0, 0),
      0.8vw 1.3333333333vw 0 rgba(255, 234, 0, 0), 0.5333333333vw 1.3333333333vw 0 rgba(255, 234, 0, 0), 0.2666666667vw 1.3333333333vw 0 rgba(255, 234, 0, 0), 0 1.3333333333vw 0 rgba(255, 234, 0, 0),
      -0.2666666667vw 1.3333333333vw 0 rgba(255, 234, 0, 0), -0.5333333333vw 1.3333333333vw 0 rgba(255, 234, 0, 0), -0.8vw 1.3333333333vw 0 rgba(255, 234, 0, 0),
      -1.0666666667vw 1.3333333333vw 0 rgba(255, 234, 0, 0), -1.3333333333vw 1.3333333333vw 0 rgba(255, 234, 0, 0), -1.3333333333vw 1.0666666667vw 0 rgba(255, 234, 0, 0),
      -1.3333333333vw 0.8vw 0 rgba(255, 234, 0, 0), -1.3333333333vw 0.5333333333vw 0 rgba(255, 234, 0, 0), -1.3333333333vw 0.2666666667vw 0 rgba(255, 234, 0, 0),
      -1.3333333333vw 0 0 rgba(255, 234, 0, 0), -1.3333333333vw -0.2666666667vw 0 rgba(255, 234, 0, 0), -1.3333333333vw -0.5333333333vw 0 rgba(255, 234, 0, 0),
      -1.3333333333vw -0.8vw 0 rgba(255, 234, 0, 0), -1.3333333333vw -1.0666666667vw 0 rgba(255, 234, 0, 0), -1.3333333333vw -1.3333333333vw 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -0.5333333333vw -0.8vw 0 #dd3f07, -0.2666666667vw -0.8vw 0 #dd3f07, 0 -0.8vw 0 #dd3f07, 0.2666666667vw -0.8vw 0 #dd3f07, 0.5333333333vw -0.8vw 0 #dd3f07, 0.8vw -0.8vw 0 #dd3f07,
      0.8vw -0.5333333333vw 0 #dd3f07, 0.8vw -0.2666666667vw 0 #dd3f07, 0.8vw 0 0 #dd3f07, 0.8vw 0.2666666667vw 0 #dd3f07, 0.8vw 0.5333333333vw 0 #dd3f07, 0.8vw 0.8vw 0 #dd3f07,
      0.5333333333vw 0.8vw 0 #dd3f07, 0.2666666667vw 0.8vw 0 #dd3f07, 0 0.8vw 0 #dd3f07, -0.2666666667vw 0.8vw 0 #dd3f07, -0.5333333333vw 0.8vw 0 #dd3f07, -0.8vw 0.8vw 0 #dd3f07,
      -0.8vw 0.5333333333vw 0 #dd3f07, -0.8vw 0.2666666667vw 0 #dd3f07, -0.8vw 0 0 #dd3f07, -0.8vw -0.2666666667vw 0 #dd3f07, -0.8vw -0.5333333333vw 0 #dd3f07, -0.8vw -0.8vw 0 #dd3f07,
      -1.0666666667vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), -0.8vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -0.5333333333vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), -0.2666666667vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      0 -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), 0.2666666667vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      0.5333333333vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), 0.8vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      1.0666666667vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), 1.3333333333vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      1.3333333333vw -1.0666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25), 1.3333333333vw -0.8vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      1.3333333333vw -0.5333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), 1.3333333333vw -0.2666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      1.3333333333vw 0 1.3333333333vw rgba(255, 234, 0, 0.25), 1.3333333333vw 0.2666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      1.3333333333vw 0.5333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), 1.3333333333vw 0.8vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      1.3333333333vw 1.0666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25), 1.3333333333vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      1.0666666667vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), 0.8vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      0.5333333333vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), 0.2666666667vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      0 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), -0.2666666667vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -0.5333333333vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), -0.8vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -1.0666666667vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), -1.3333333333vw 1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -1.3333333333vw 1.0666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25), -1.3333333333vw 0.8vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -1.3333333333vw 0.5333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), -1.3333333333vw 0.2666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -1.3333333333vw 0 1.3333333333vw rgba(255, 234, 0, 0.25), -1.3333333333vw -0.2666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -1.3333333333vw -0.5333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25), -1.3333333333vw -0.8vw 1.3333333333vw rgba(255, 234, 0, 0.25),
      -1.3333333333vw -1.0666666667vw 1.3333333333vw rgba(255, 234, 0, 0.25), -1.3333333333vw -1.3333333333vw 1.3333333333vw rgba(255, 234, 0, 0.25);
  }
}

@keyframes roulettePrizeNumberTextM-pigSize {
  0% {
    text-shadow: -3px -4px 0 #dd3f07, -2px -4px 0 #dd3f07, -1px -4px 0 #dd3f07, 0 -4px 0 #dd3f07, 1px -4px 0 #dd3f07, 2px -4px 0 #dd3f07, 3px -4px 0 #dd3f07, 4px -4px 0 #dd3f07, 4px -3px 0 #dd3f07,
      4px -2px 0 #dd3f07, 4px -1px 0 #dd3f07, 4px 0 0 #dd3f07, 4px 1px 0 #dd3f07, 4px 2px 0 #dd3f07, 4px 3px 0 #dd3f07, 4px 4px 0 #dd3f07, 3px 4px 0 #dd3f07, 2px 4px 0 #dd3f07, 1px 4px 0 #dd3f07,
      0 4px 0 #dd3f07, -1px 4px 0 #dd3f07, -2px 4px 0 #dd3f07, -3px 4px 0 #dd3f07, -4px 4px 0 #dd3f07, -4px 3px 0 #dd3f07, -4px 2px 0 #dd3f07, -4px 1px 0 #dd3f07, -4px 0 0 #dd3f07, -4px -1px 0 #dd3f07,
      -4px -2px 0 #dd3f07, -4px -3px 0 #dd3f07, -4px -4px 0 #dd3f07, -5px -6px 0 rgba(255, 234, 0, 0), -4px -6px 0 rgba(255, 234, 0, 0), -3px -6px 0 rgba(255, 234, 0, 0),
      -2px -6px 0 rgba(255, 234, 0, 0), -1px -6px 0 rgba(255, 234, 0, 0), 0 -6px 0 rgba(255, 234, 0, 0), 1px -6px 0 rgba(255, 234, 0, 0), 2px -6px 0 rgba(255, 234, 0, 0),
      3px -6px 0 rgba(255, 234, 0, 0), 4px -6px 0 rgba(255, 234, 0, 0), 5px -6px 0 rgba(255, 234, 0, 0), 6px -6px 0 rgba(255, 234, 0, 0), 6px -5px 0 rgba(255, 234, 0, 0),
      6px -4px 0 rgba(255, 234, 0, 0), 6px -3px 0 rgba(255, 234, 0, 0), 6px -2px 0 rgba(255, 234, 0, 0), 6px -1px 0 rgba(255, 234, 0, 0), 6px 0 0 rgba(255, 234, 0, 0), 1px 6px 0 rgba(255, 234, 0, 0),
      2px 6px 0 rgba(255, 234, 0, 0), 3px 6px 0 rgba(255, 234, 0, 0), 4px 6px 0 rgba(255, 234, 0, 0), 5px 6px 0 rgba(255, 234, 0, 0), 6px 6px 0 rgba(255, 234, 0, 0), 5px 6px 0 rgba(255, 234, 0, 0),
      4px 6px 0 rgba(255, 234, 0, 0), 3px 6px 0 rgba(255, 234, 0, 0), 2px 6px 0 rgba(255, 234, 0, 0), 1px 6px 0 rgba(255, 234, 0, 0), 0 6px 0 rgba(255, 234, 0, 0), -1px 6px 0 rgba(255, 234, 0, 0),
      -2px 6px 0 rgba(255, 234, 0, 0), -3px 6px 0 rgba(255, 234, 0, 0), -4px 6px 0 rgba(255, 234, 0, 0), -5px 6px 0 rgba(255, 234, 0, 0), -6px 6px 0 rgba(255, 234, 0, 0),
      -6px 5px 0 rgba(255, 234, 0, 0), -6px 4px 0 rgba(255, 234, 0, 0), -6px 3px 0 rgba(255, 234, 0, 0), -6px 2px 0 rgba(255, 234, 0, 0), -6px 1px 0 rgba(255, 234, 0, 0), -6px 0 0 rgba(255, 234, 0, 0),
      -6px -1px 0 rgba(255, 234, 0, 0), -6px -2px 0 rgba(255, 234, 0, 0), -6px -3px 0 rgba(255, 234, 0, 0), -6px -4px 0 rgba(255, 234, 0, 0), -6px -5px 0 rgba(255, 234, 0, 0),
      -6px -6px 0 rgba(255, 234, 0, 0);
  }

  to {
    text-shadow: -4px -4px 0 #dd3f07, -3px -4px 0 #dd3f07, -2px -4px 0 #dd3f07, -1px -4px 0 #dd3f07, 0 -4px 0 #dd3f07, 1px -4px 0 #dd3f07, 2px -4px 0 #dd3f07, 3px -4px 0 #dd3f07, 4px -4px 0 #dd3f07,
      4px -3px 0 #dd3f07, 4px -2px 0 #dd3f07, 4px -1px 0 #dd3f07, 4px 0 0 #dd3f07, 4px 1px 0 #dd3f07, 4px 2px 0 #dd3f07, 4px 3px 0 #dd3f07, 4px 4px 0 #dd3f07, 3px 4px 0 #dd3f07, 2px 4px 0 #dd3f07,
      1px 4px 0 #dd3f07, 0 4px 0 #dd3f07, -1px 4px 0 #dd3f07, -2px 4px 0 #dd3f07, -3px 4px 0 #dd3f07, -4px 4px 0 #dd3f07, -4px 3px 0 #dd3f07, -4px 2px 0 #dd3f07, -4px 1px 0 #dd3f07, -4px 0 0 #dd3f07,
      -4px -1px 0 #dd3f07, -4px -2px 0 #dd3f07, -4px -3px 0 #dd3f07, -4px -4px 0 #dd3f07, -5px -6px 6px rgba(255, 234, 0, 0.25), -4px -6px 6px rgba(255, 234, 0, 0.25),
      -3px -6px 6px rgba(255, 234, 0, 0.25), -2px -6px 6px rgba(255, 234, 0, 0.25), -1px -6px 6px rgba(255, 234, 0, 0.25), 0 -6px 6px rgba(255, 234, 0, 0.25), 1px -6px 6px rgba(255, 234, 0, 0.25),
      2px -6px 6px rgba(255, 234, 0, 0.25), 3px -6px 6px rgba(255, 234, 0, 0.25), 4px -6px 6px rgba(255, 234, 0, 0.25), 5px -6px 6px rgba(255, 234, 0, 0.25), 6px -6px 6px rgba(255, 234, 0, 0.25),
      6px -5px 6px rgba(255, 234, 0, 0.25), 6px -4px 6px rgba(255, 234, 0, 0.25), 6px -3px 6px rgba(255, 234, 0, 0.25), 6px -2px 6px rgba(255, 234, 0, 0.25), 6px -1px 6px rgba(255, 234, 0, 0.25),
      6px 0 6px rgba(255, 234, 0, 0.25), 1px 6px 6px rgba(255, 234, 0, 0.25), 2px 6px 6px rgba(255, 234, 0, 0.25), 3px 6px 6px rgba(255, 234, 0, 0.25), 4px 6px 6px rgba(255, 234, 0, 0.25),
      5px 6px 6px rgba(255, 234, 0, 0.25), 6px 6px 6px rgba(255, 234, 0, 0.25), 5px 6px 6px rgba(255, 234, 0, 0.25), 4px 6px 6px rgba(255, 234, 0, 0.25), 3px 6px 6px rgba(255, 234, 0, 0.25),
      2px 6px 6px rgba(255, 234, 0, 0.25), 1px 6px 6px rgba(255, 234, 0, 0.25), 0 6px 6px rgba(255, 234, 0, 0.25), -1px 6px 6px rgba(255, 234, 0, 0.25), -2px 6px 6px rgba(255, 234, 0, 0.25),
      -3px 6px 6px rgba(255, 234, 0, 0.25), -4px 6px 6px rgba(255, 234, 0, 0.25), -5px 6px 6px rgba(255, 234, 0, 0.25), -6px 6px 6px rgba(255, 234, 0, 0.25), -6px 5px 6px rgba(255, 234, 0, 0.25),
      -6px 4px 6px rgba(255, 234, 0, 0.25), -6px 3px 6px rgba(255, 234, 0, 0.25), -6px 2px 6px rgba(255, 234, 0, 0.25), -6px 1px 6px rgba(255, 234, 0, 0.25), -6px 0 6px rgba(255, 234, 0, 0.25),
      -6px -1px 6px rgba(255, 234, 0, 0.25), -6px -2px 6px rgba(255, 234, 0, 0.25), -6px -3px 6px rgba(255, 234, 0, 0.25), -6px -4px 6px rgba(255, 234, 0, 0.25), -6px -5px 6px rgba(255, 234, 0, 0.25),
      -6px -6px 6px rgba(255, 234, 0, 0.25);
  }
}

@keyframes rouletteMainPart {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes rouletteMainBtnShadow {
  0% {
    box-shadow: 0 0 2.6666666667vw rgba(0, 0, 0, 0.3), 0 0.5333333333vw 0.8vw 1px rgba(0, 0, 0, 0.35), inset -1px -1px 0 rgba(0, 0, 0, 0.2), inset -1px 0 1px #fef7da,
      inset 1px 1px 0 rgba(255, 255, 255, 0.8);
  }

  to {
    box-shadow: 0 0 8vw rgba(0, 0, 0, 0.9), 0 0.5333333333vw 0.8vw 1px rgba(0, 0, 0, 0.35), inset -1px -1px 0 rgba(0, 0, 0, 0.2), inset -1px 0 1px #fef7da, inset 1px 1px 0 rgba(255, 255, 255, 0.8);
  }
}

@keyframes rouletteMainBtnShadow-pigSize {
  0% {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3), 0 3px 5px 1px rgba(0, 0, 0, 0.35), inset -1px -1px 0 rgba(0, 0, 0, 0.2), inset -1px 0 1px #fef7da, inset 1px 1px 0 rgba(255, 255, 255, 0.8);
  }

  to {
    box-shadow: 0 0 45px rgba(0, 0, 0, 0.9), 0 3px 5px 1px rgba(0, 0, 0, 0.35), inset -1px -1px 0 rgba(0, 0, 0, 0.2), inset -1px 0 1px #fef7da, inset 1px 1px 0 rgba(255, 255, 255, 0.8);
  }
}

@keyframes rouletteMainBtnLight {
  0% {
    transform: translate3d(-60%, 0, 0) rotate(-45deg);
  }

  to {
    transform: translate3d(60%, 0, 0) rotate(-45deg);
  }
}

@keyframes rouletteBoxCoin {
  0% {
    opacity: 0.5;
    transform: scale3d(0.6, 0.6, 1);
  }

  25% {
    opacity: 0.8;
    transform: scale3d(0.8, 0.8, 1);
  }

  50% {
    opacity: 1;
    transform: scaleX(1);
  }

  75% {
    opacity: 0.6;
    transform: scale3d(1.15, 1.15, 1);
  }

  to {
    opacity: 0.1;
    transform: scale3d(1.3, 1.3, 1);
  }
}

@keyframes roulette_P6789_N0 {
  0% {
    transform: rotate(-2turn);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes roulette_P6_N1 {
  0% {
    transform: rotate(-780deg);
  }

  to {
    transform: rotate(-60deg);
  }
}

@keyframes roulette_P6_N2_P9_N3 {
  0% {
    transform: rotate(-840deg);
  }

  to {
    transform: rotate(-120deg);
  }
}

@keyframes roulette_P6_N3_P8_N4 {
  0% {
    transform: rotate(-900deg);
  }

  to {
    transform: rotate(-180deg);
  }
}

@keyframes roulette_P6_N4_P9_N6 {
  0% {
    transform: rotate(-960deg);
  }

  to {
    transform: rotate(-240deg);
  }
}

@keyframes roulette_P6_N5 {
  0% {
    transform: rotate(-1020deg);
  }

  to {
    transform: rotate(-300deg);
  }
}

@keyframes roulette_P7_N1 {
  0% {
    transform: rotate(-771.4285714286deg);
  }

  to {
    transform: rotate(-51.4285714286deg);
  }
}

@keyframes roulette_P7_N2 {
  0% {
    transform: rotate(-822.8571428571deg);
  }

  to {
    transform: rotate(-102.8571428571deg);
  }
}

@keyframes roulette_P7_N3 {
  0% {
    transform: rotate(-874.2857142857deg);
  }

  to {
    transform: rotate(-154.2857142857deg);
  }
}

@keyframes roulette_P7_N4 {
  0% {
    transform: rotate(-925.7142857143deg);
  }

  to {
    transform: rotate(-205.7142857143deg);
  }
}

@keyframes roulette_P7_N5 {
  0% {
    transform: rotate(-977.1428571429deg);
  }

  to {
    transform: rotate(-257.1428571429deg);
  }
}

@keyframes roulette_P7_N6 {
  0% {
    transform: rotate(-1028.5714285714deg);
  }

  to {
    transform: rotate(-308.5714285714deg);
  }
}

@keyframes roulette_P8_N1 {
  0% {
    transform: rotate(-765deg);
  }

  to {
    transform: rotate(-45deg);
  }
}

@keyframes roulette_P8_N2 {
  0% {
    transform: rotate(-810deg);
  }

  to {
    transform: rotate(-90deg);
  }
}

@keyframes roulette_P8_N3 {
  0% {
    transform: rotate(-855deg);
  }

  to {
    transform: rotate(-135deg);
  }
}

@keyframes roulette_P8_N5 {
  0% {
    transform: rotate(-945deg);
  }

  to {
    transform: rotate(-225deg);
  }
}

@keyframes roulette_P8_N6 {
  0% {
    transform: rotate(-990deg);
  }

  to {
    transform: rotate(-270deg);
  }
}

@keyframes roulette_P8_N7 {
  0% {
    transform: rotate(-1035deg);
  }

  to {
    transform: rotate(-315deg);
  }
}

@keyframes roulette_P9_N1 {
  0% {
    transform: rotate(-760deg);
  }

  to {
    transform: rotate(-40deg);
  }
}

@keyframes roulette_P9_N2 {
  0% {
    transform: rotate(-800deg);
  }

  to {
    transform: rotate(-80deg);
  }
}

@keyframes roulette_P9_N4 {
  0% {
    transform: rotate(-880deg);
  }

  to {
    transform: rotate(-160deg);
  }
}

@keyframes roulette_P9_N5 {
  0% {
    transform: rotate(-920deg);
  }

  to {
    transform: rotate(-200deg);
  }
}

@keyframes roulette_P9_N7 {
  0% {
    transform: rotate(-1000deg);
  }

  to {
    transform: rotate(-280deg);
  }
}

@keyframes roulette_P9_N8 {
  0% {
    transform: rotate(-1040deg);
  }

  to {
    transform: rotate(-320deg);
  }
}

@font-face {
  font-family: rouletteStar;
  src: url(https://csi.20icipp.com/img/static/roulette/fonts/icomoon.eot?p6x2l0=);
  src: url(https://csi.20icipp.com/img/static/roulette/fonts/icomoon.ttf?p6x2l0=) format('truetype');
}

.roulette_awards_item.special .roulette_awards_medals,
.roulette_awards_item > div,
.roulette_main,
.roulette_main:after,
.roulette_main:before,
.roulette_main_wrap:before,
.roulette_rule_title,
.roulette_winner,
.roulette_winner_wrap:after,
.roulette_winner_wrap:before {
  background-size: 100% 100%;
}

.roulette_awards_item,
.roulette_main,
.roulette_winner,
.roulette_winner_wrap:after,
.roulette_winner_wrap:before {
  height: 0;
}

.roulette_winner_wrap:after,
.roulette_winner_wrap:before {
  content: '';
  position: absolute;
}

.roulette_awards_item > div,
.roulette_main:after,
.roulette_main:before,
.roulette_main_coin,
.roulette_main_coinWrap,
.roulette_main_numberTimes > div,
.roulette_main_wrap:after,
.roulette_main_wrap:before,
.roulette_popup_box,
.roulette_winner_wrap {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.roulette {
  background-color: #190023;
  color: #fff;
  font-family: Arial, Helvetica, Microsoft YaHei, Microsoft JhengHei, PingFangTC, sans-serif;
  font-weight: 400;
  line-height: 1;
  margin: 0 auto;
  max-width: 568px;
  min-width: 320px;
  width: 100%;
}

.roulette * {
  box-sizing: border-box;
}

.roulette_main,
.roulette_wrap {
  margin: 0 auto;
  max-width: 812px;
}

.roulette_main {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/babu/image/6225b4f336723.png);
  margin: 0 auto;
  padding-bottom: 120%;
  position: relative;
  width: 100%;
}

.roulette_main:after,
.roulette_main:before {
  animation: rouletteDecoShake 25s infinite;
  content: '';
}

.roulette_main:before {
  animation-direction: alternate;
  animation-timing-function: linear;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_balloon_m.png);
}

.roulette_main:after {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_ball_m.png);
}

.roulette_main_wrap {
  height: 78.8888888889%;
  left: 2.6666666667%;
  padding: 7.5%;
  position: absolute;
  top: 8.3333333333%;
  width: 94.6666666667%;
}

.roulette_main_wrap:after,
.roulette_main_wrap:before {
  content: '';
  z-index: 2;
}

.roulette_main_wrap:before {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/babu/image/6225b4f37e777.png);
}

.roulette_main_wrap:after {
  animation: rouletteNeonLight 1s steps(2) infinite;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_neonLight_m.png);
  background-size: 200% 100%;
}

.roulette_main_part {
  border-radius: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  transform: rotate3d(0, 0, 0, 0deg);
  transition: filter 0.4s;
  width: 100%;
}

.roulette_main_part_list {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 2.6666666667vw;
  height: 50%;
  justify-content: center;
  padding-bottom: 1.5%;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
}

.roulette_main_part_list:before {
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  background-image: linear-gradient(0deg, #ffcf00, #ff0 14%, #ff0 35%, #fff 42%, #ffc600 72%);
  content: '';
  height: 100%;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  width: 2px;
  z-index: 1;
}

.roulette_main_part_list:after {
  font-family: Verdana, sans-serif;
  font-size: 11.2vw;
  font-weight: 700;
  position: relative;
  text-shadow: -0.5333333333vw -0.8vw 0 #dd3f07, -0.2666666667vw -0.8vw 0 #dd3f07, 0 -0.8vw 0 #dd3f07, 0.2666666667vw -0.8vw 0 #dd3f07, 0.5333333333vw -0.8vw 0 #dd3f07, 0.8vw -0.8vw 0 #dd3f07,
    0.8vw -0.5333333333vw 0 #dd3f07, 0.8vw -0.2666666667vw 0 #dd3f07, 0.8vw 0 0 #dd3f07, 0.8vw 0.2666666667vw 0 #dd3f07, 0.8vw 0.5333333333vw 0 #dd3f07, 0.8vw 0.8vw 0 #dd3f07,
    0.5333333333vw 0.8vw 0 #dd3f07, 0.2666666667vw 0.8vw 0 #dd3f07, 0 0.8vw 0 #dd3f07, -0.2666666667vw 0.8vw 0 #dd3f07, -0.5333333333vw 0.8vw 0 #dd3f07, -0.8vw 0.8vw 0 #dd3f07,
    -0.8vw 0.5333333333vw 0 #dd3f07, -0.8vw 0.2666666667vw 0 #dd3f07, -0.8vw 0 0 #dd3f07, -0.8vw -0.2666666667vw 0 #dd3f07, -0.8vw -0.5333333333vw 0 #dd3f07, -0.8vw -0.8vw 0 #dd3f07;
}

.roulette_main_part_list.special:after {
  -webkit-text-stroke: 0.8vw #dd3f07;
  content: '';
  font-family: rouletteStar;
  text-shadow: none;
}

.roulette_main_part_list.special > .roulette_main_part_color:after,
.roulette_main_part_list.special > .roulette_main_part_color:before {
  background-color: #ff0056;
}

.roulette_main_part_list:first-of-type:after {
  content: '';
}

.roulette_main_part_list:nth-of-type(2):after {
  content: '';
}

.roulette_main_part_list:nth-of-type(3):after {
  content: '';
}

.roulette_main_part_list:nth-of-type(4):after {
  content: '';
}

.roulette_main_part_list:nth-of-type(5):after {
  content: '';
}

.roulette_main_part_list:nth-of-type(6):after {
  content: '';
}

.roulette_main_part_list:nth-of-type(7):after {
  content: '';
}

.roulette_main_part_list:nth-of-type(8):after {
  content: '';
}

.roulette_main_part_list:nth-of-type(9):after {
  content: '';
}

.roulette_main_part_color {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.roulette_main_part_color:after,
.roulette_main_part_color:before {
  content: '';
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.roulette_main_part_color:before {
  left: -50%;
  transform-origin: 100% 100%;
}

.roulette_main_part_color:after {
  left: 50%;
  transform-origin: 0 100%;
}

.roulette_main_part-6 > .roulette_main_part_list {
  left: 25%;
  width: 50%;
}

.roulette_main_part-6 > .roulette_main_part_list:before {
  transform: rotate(-30deg);
}

.roulette_main_part-6 > .roulette_main_part_list.special > .roulette_main_part_color:after {
  background: none;
}

.roulette_main_part-6 > .roulette_main_part_list:nth-of-type(2) {
  transform: rotate(60deg);
}

.roulette_main_part-6 > .roulette_main_part_list:nth-of-type(3) {
  transform: rotate(120deg);
}

.roulette_main_part-6 > .roulette_main_part_list:nth-of-type(4) {
  transform: rotate(180deg);
}

.roulette_main_part-6 > .roulette_main_part_list:nth-of-type(5) {
  transform: rotate(240deg);
}

.roulette_main_part-6 > .roulette_main_part_list:nth-of-type(6) {
  transform: rotate(300deg);
}

.roulette_main_part-6 > .roulette_main_part_list:nth-of-type(odd) > .roulette_main_part_color:before {
  background-image: linear-gradient(-41deg, #94fd01 12%, #1a6642 54%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-6 > .roulette_main_part_list:nth-of-type(2n) > .roulette_main_part_color:before {
  background-image: linear-gradient(-41deg, #fbc108 12%, #f17007 54%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-6 .roulette_main_part_color:before {
  transform: rotate(60deg) skew(30deg);
}

.roulette_main_part-7 > .roulette_main_part_list {
  left: 28.3%;
  width: 43.4%;
}

.roulette_main_part-7 > .roulette_main_part_list:before {
  transform: rotate(-25.7142857143deg);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(2) {
  transform: rotate(51.4285714286deg);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(3) {
  transform: rotate(102.8571428571deg);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(4) {
  transform: rotate(154.2857142857deg);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(5) {
  transform: rotate(205.7142857143deg);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(6) {
  transform: rotate(257.1428571429deg);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(7) {
  transform: rotate(308.5714285714deg);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(8) {
  transform: rotate(1turn);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_color:before,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_color:before,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(8) > .roulette_main_part_color:before {
  background-image: linear-gradient(-38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_color:after,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_color:after,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(8) > .roulette_main_part_color:after {
  background-image: linear-gradient(38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_color:before,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_color:before {
  background-image: linear-gradient(-38deg, #fbc108 12%, #f17007 52%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_color:after,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_color:after {
  background-image: linear-gradient(38deg, #fbc108 12%, #f17007 52%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_color:before,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(7) > .roulette_main_part_color:before {
  background-image: linear-gradient(-38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_color:after,
.roulette_main_part-7 > .roulette_main_part_list:nth-of-type(7) > .roulette_main_part_color:after {
  background-image: linear-gradient(38deg, #94fd01 12%, #1a6642 52%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-7 .roulette_main_part_color:before {
  transform: rotate(64.4deg) skew(38.8deg);
}

.roulette_main_part-7 .roulette_main_part_color:after {
  transform: rotate(-64.4deg) skew(-38.8deg);
}

.roulette_main_part-8 > .roulette_main_part_list {
  left: 30.9%;
  width: 38.2%;
}

.roulette_main_part-8 > .roulette_main_part_list:before {
  transform: rotate(-22.5deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(2) {
  transform: rotate(45deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(3) {
  transform: rotate(90deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(4) {
  transform: rotate(135deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(5) {
  transform: rotate(180deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(6) {
  transform: rotate(225deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(7) {
  transform: rotate(270deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(8) {
  transform: rotate(315deg);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(odd) > .roulette_main_part_color:before {
  background-image: linear-gradient(-35.3deg, #fbc108 12%, #f17007 50%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(odd) > .roulette_main_part_color:after {
  background-image: linear-gradient(35.3deg, #fbc108 12%, #f17007 50%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(2n) > .roulette_main_part_color:before {
  background-image: linear-gradient(-35.3deg, #94fd01 12%, #1a6642 50%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-8 > .roulette_main_part_list:nth-of-type(2n) > .roulette_main_part_color:after {
  background-image: linear-gradient(35.3deg, #94fd01 12%, #1a6642 50%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-8 .roulette_main_part_color:before {
  transform: rotate(67.5deg) skew(45deg);
}

.roulette_main_part-8 .roulette_main_part_color:after {
  transform: rotate(-67.5deg) skew(-45deg);
}

.roulette_main_part-9 > .roulette_main_part_list {
  left: 33%;
  width: 34%;
}

.roulette_main_part-9 > .roulette_main_part_list:before {
  transform: rotate(-20deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(2) {
  transform: rotate(40deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(3) {
  transform: rotate(80deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(4) {
  transform: rotate(120deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(5) {
  transform: rotate(160deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(6) {
  transform: rotate(200deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(7) {
  transform: rotate(240deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(8) {
  transform: rotate(280deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(9) {
  transform: rotate(320deg);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_color:before,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_color:before,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(8) > .roulette_main_part_color:before {
  background-image: linear-gradient(-32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_color:after,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_color:after,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(8) > .roulette_main_part_color:after {
  background-image: linear-gradient(32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_color:before,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_color:before,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(9) > .roulette_main_part_color:before {
  background-image: linear-gradient(-32.7deg, #fbc108 12%, #f17007 48%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_color:after,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_color:after,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(9) > .roulette_main_part_color:after {
  background-image: linear-gradient(32.7deg, #fbc108 12%, #f17007 48%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_color:before,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(7) > .roulette_main_part_color:before {
  background-image: linear-gradient(-32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_color:after,
.roulette_main_part-9 > .roulette_main_part_list:nth-of-type(7) > .roulette_main_part_color:after {
  background-image: linear-gradient(32.7deg, #94fd01 12%, #1a6642 48%, hsla(0, 0%, 100%, 0) 0);
}

.roulette_main_part-9 .roulette_main_part_color:before {
  transform: rotate(70deg) skew(50deg);
}

.roulette_main_part-9 .roulette_main_part_color:after {
  transform: rotate(-70deg) skew(-50deg);
}

.roulette_main_part_award {
  line-height: 1.2;
  position: relative;
  text-align: center;
  width: 70%;
}

.roulette_main_btn,
.roulette_main_numberTimes {
  background-size: 100% 100%;
  position: absolute;
  z-index: 3;
}

.roulette_main_btn {
  animation: rouletteMainBtnShadow 0.5s infinite alternate both;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_btn_m.png);
  background-position: 0 0;
  background-size: 200% 100%;
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 0 7.4666666667vw rgba(0, 0, 0, 0.75), 0 0.5333333333vw 0.8vw 1px rgba(0, 0, 0, 0.35), inset -1px -1px 0 rgba(0, 0, 0, 0.2), inset -1px 0 1px #fef7da,
    inset 1px 1px 0 rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  height: 18.5915492958%;
  left: 0;
  margin: auto;
  overflow: hidden;
  padding-bottom: 1.5%;
  right: 0;
  top: 0;
  width: 18.5915492958%;
}

.roulette_main_btn:after {
  animation: rouletteMainBtnLight 2.5s infinite both;
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, 0.8) 60%, hsla(0, 0%, 100%, 0.4) 0, hsla(0, 0%, 100%, 0));
  content: '';
  height: 36%;
  left: -32%;
  position: absolute;
  top: 30%;
  transform: translate3d(80%, 0, 0) rotate(-45deg);
  width: 180%;
}

.roulette_main_logo {
  margin: auto;
  max-width: 75%;
  pointer-events: none;
}

.roulette_main_numberTimes_left {
  height: 0;
  padding-bottom: 17.549296%;
  left: 2%;
  top: 5%;
  text-align: center;
  width: 24.2253521127%;
  position: absolute;
}

.roulette_main_numberTimes {
  background-color: #fdea15;
  background-image: url(https://jiliwin.9terawolf.com/images/wof/Coin_Frame.png);
  border-radius: 8.1395%/17.0732%;
  box-shadow: 0 0 4.8vw 0.8vw rgba(252, 255, 0, 0.6);
  height: 0;
  padding-bottom: 11.5492957746%;
  right: 1%;
  top: 2%;
  width: 24.2253521127%;
}

.roulette_main_numberTimes .point_rate {
  background-color: rgba(95, 47, 245, 0.9);
  border-radius: 100px;
  box-shadow: inset 0 0 0 1px #d3c5fa, inset 0 0 10px #b670f7, 0 2px 6px rgba(0, 0, 0, 0.8);
  color: #fff;
  flex-wrap: nowrap;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  height: 25px;
  left: 50%;
  line-height: 25px;
  padding: 0 15px;
  position: absolute;
  text-shadow: 0 0 0 #fff;
  text-shadow: 0 1px 1px #27095c;
  transform: skew(0deg) translateX(-50%) translateY(-30px);
  width: auto !important;
}

.roulette_main_numberTimes .point_rate b {
  color: #ffefbd;
  font-size: 14px;
  font-weight: 700;
}

.roulette_main_numberTimes > div {
  align-content: center;
  align-items: center;
  color: #ff4100;
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  justify-content: center;
  padding-bottom: 1%;
  text-align: center;
  text-shadow: 0.2666666667vw 0.5333333333vw 0 #f3cb00;
  transform: skew(-6deg);
}

.roulette_main_numberTimes > div > div {
  width: 100%;
}

.roulette_main_numberTimes span:first-of-type {
  font-size: 4vw;
}

.roulette_main_numberTimes span:nth-of-type(2) {
  font-size: 1.8666666667vw;
  margin: 6% 0 0 1%;
}

.roulette_main_coin {
  pointer-events: none;
  z-index: 3;
}

.roulette_main_coinWrap {
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: rouletteBoxCoin;
  animation-timing-function: linear;
}

.roulette_main_coinWrap:first-of-type {
  animation-delay: -0.5s;
  animation-duration: 4s;
}

.roulette_main_coinWrap:nth-of-type(2) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.roulette_main_coinWrap:nth-of-type(3) {
  animation-delay: -2.5s;
  animation-duration: 8s;
}

.roulette_main_coinWrap:nth-of-type(4) {
  animation-delay: -1.5s;
  animation-duration: 6s;
}

.roulette_main_coinWrap:nth-of-type(5) {
  animation-delay: -1s;
  animation-duration: 5s;
}

.roulette_main_coinWrap:nth-of-type(6) {
  animation-delay: -3s;
  animation-duration: 9s;
}

.roulette_main_coin_1,
.roulette_main_coin_2,
.roulette_main_coin_3,
.roulette_main_coin_4,
.roulette_main_coin_5,
.roulette_main_coin_6 {
  position: absolute;
}

.roulette_main_coin_1 {
  left: 83.3%;
  top: 49.6%;
  width: 17.0666666667%;
}

.roulette_main_coin_2 {
  left: 4.5%;
  top: 30.5%;
  width: 12.2666666667%;
}

.roulette_main_coin_3 {
  left: 77.5%;
  top: 22%;
  width: 22.5333333333%;
}

.roulette_main_coin_4 {
  left: 75.5%;
  top: 72.5%;
  width: 20.5333333333%;
}

.roulette_main_coin_5 {
  left: 0;
  top: 72.5%;
  width: 40.4%;
}

.roulette_main_coin_6 {
  left: 20%;
  top: 5%;
  width: 9.6%;
}

.roulette_wrap {
  overflow: hidden;
}

.roulette_minor {
  background-color: black;
  padding: 0 20px 70px;
}

.roulette_awards {
  list-style-type: none;
  padding: 0 5px;
}

.roulette_awards_item {
  padding-bottom: 17.5593220339%;
  position: relative;
  width: 100%;
}

.roulette_awards_item.special > div {
  animation: rouletteSpecialAwardM 0.6s infinite alternate both;
  border-radius: 40px;
}

.roulette_awards_item.special .roulette_awards_medals {
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_awards_medals_0.png);
  height: 100%;
  margin-top: 1.8%;
  width: 11.7216117216%;
}

.roulette_awards_item.special .roulette_awards_prize {
  width: 29.304029304%;
}

.roulette_awards_item:first-of-type .roulette_awards_medals {
  background-position: 0 0;
  color: #be5a18;
}

.roulette_awards_item:nth-of-type(2) .roulette_awards_medals {
  background-position: 33.3333% 0;
  color: #6f6f80;
}

.roulette_awards_item:nth-of-type(3) .roulette_awards_medals {
  background-position: 66.6666% 0;
  color: #7c5552;
}

.roulette_awards_item:first-of-type .roulette_awards_medals:before {
  content: '1';
}

.roulette_awards_item:nth-of-type(2) .roulette_awards_medals:before {
  content: '2';
}

.roulette_awards_item:nth-of-type(3) .roulette_awards_medals:before {
  content: '3';
}

.roulette_awards_item:nth-of-type(4) .roulette_awards_medals:before {
  content: '4';
}

.roulette_awards_item:nth-of-type(5) .roulette_awards_medals:before {
  content: '5';
}

.roulette_awards_item:nth-of-type(6) .roulette_awards_medals:before {
  content: '6';
}

.roulette_awards_item:nth-of-type(7) .roulette_awards_medals:before {
  content: '7';
}

.roulette_awards_item:nth-of-type(8) .roulette_awards_medals:before {
  content: '8';
}

.roulette_awards_item:nth-of-type(9) .roulette_awards_medals:before {
  content: '9';
}

.roulette_awards_item > div {
  align-items: center;
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/jaya9/image/wof/prize_table.png);
  display: flex;
  justify-content: space-between;
  padding: 0 4.7457627119% 0 2.7118644068%;
}

.roulette_awards_item + .roulette_awards_item {
  margin-top: 3.3898305085%;
}

.roulette_awards_medals {
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_awards_medals_m.png);
  background-position: 100% 0;
  background-size: 400% 100%;
  color: #674170;
  font-size: 3.7333333333vw;
  font-weight: 700;
  height: 70%;
  width: 13.2564102564%;
}

.roulette_awards_medals,
.roulette_awards_prize {
  align-items: center;
  display: flex;
  justify-content: center;
}

.roulette_awards_prize {
  height: 90%;
  position: relative;
  width: 20.1465201465%;
}

.roulette_awards_prize > img {
  display: block;
  max-height: 90%;
  max-width: 100%;
}

.roulette_awards_text {
  font-size: 4.2666666667vw;
  text-align: center;
  text-shadow: 0 1px 0.8vw rgba(0, 0, 0, 0.8);
  width: 55%;
}

.roulette_winner {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/jaya9/image/wof/smallpanel.png);
  margin: 29.8507462687% 0 26.8656716418%;
  padding-bottom: 82.0895522388%;
  position: relative;
  width: 100%;
}

.roulette_winner_wrap {
  display: flex;
  flex-direction: column;
  padding: 4vw;
}

.roulette_winner_wrap:after,
.roulette_winner_wrap:before {
  left: 50%;
  z-index: 1;
}

.roulette_winner_wrap:before {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/cms/jaya9/image/wof/trophy_krikya.png);
  padding-bottom: 28.6268656716%;
  top: 0;
  transform: translate(-50%, -95.5%);
  width: 62.0895522388%;
  height: 20%;
}

.roulette_winner_wrap:after {
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_winnerList_b_m.png);
  bottom: 0;
  padding-bottom: 25.3731343284%;
  transform: translate(-50%, 42%);
  width: 124.0677966102%;
}

.roulette_winner_head {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 20px;
  display: flex;
  margin-bottom: 12px;
  position: relative;
}

.roulette_winner_headItem {
  color: #ffffff;
  cursor: pointer;
  flex: 1 1 0;
  font-size: 16px;
  line-height: 34px;
  position: relative;
  text-align: center;
  transition: color 0.4s, text-shadow 0.4s;
  z-index: 1;
}

.roulette_winner_headCursor {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/cdn.v3c33.com/v3/wheel/rouletteMain_winner_btn.png);
  background-size: 100% 100%;
  height: 0;
  margin: -3px 0px 0px -21px;
  padding-bottom: 14.3396226415%;
  position: absolute;
  top: 0;
  transition: left 0.4s;
  width: 60.830189%;
}

.roulette_winner_head.winnerList > div:first-of-type {
  color: #fff;
  text-shadow: 0 1px 1px #5a29a7;
}

.roulette_winner_head.winnerList + .roulette_winner_slider > ul,
.roulette_winner_head.winnerList > .roulette_winner_headCursor {
  left: 0;
}

.roulette_winner_head.winnerRecord > div:nth-of-type(2) {
  color: #fff;
  text-shadow: 0 1px 1px #5a29a7;
}

.roulette_winner_head.winnerRecord > .roulette_winner_headCursor {
  left: 50%;
}

.roulette_winner_head.winnerRecord + .roulette_winner_slider > ul:first-of-type {
  left: 100%;
}

.roulette_winner_head.winnerRecord + .roulette_winner_slider > ul:nth-of-type(2) {
  left: -100%;
}

.roulette_winner_slider {
  display: flex;
  flex: 1 1 0;
  overflow: hidden;
}

.roulette_winner_list {
  flex: 0 0 100%;
  overflow-y: auto;
  position: relative;
  transition: left 0.4s;
}

.roulette_winner_listItem {
  display: flex;
  font-size: 4.2666666667vw;
  justify-content: space-between;
  line-height: 9.6vw;
  padding: 0 6%;
}

.roulette_winner_listItem:nth-of-type(2n) {
  background-color: rgba(255, 255, 255, 0.06);
}

.roulette_winner_date {
  font-size: 3.2vw;
}

.roulette_winner_prize {
  color: #f56908;
  flex: 0 1 44%;
  text-align: center;
}

.roulette_rule {
  background-color: #1a6642;
  border-radius: 20px;
  box-shadow: inset 0 1.0666666667vw 1.8666666667vw rgba(255, 255, 255, 0.3), inset 0 -1.0666666667vw 1.8666666667vw rgba(0, 0, 0, 0.25), 0 0 12vw rgba(0, 0, 0, 0.7);
  line-height: 1.5;
  min-height: 125px;
  padding: 40px 16px 20px;
  position: relative;
}

.roulette_rule_title {
  background-image: url(https://s3.ap-southeast-1.amazonaws.com/cdn.v3c33.com/v3/wheel/roulette_rule_title.png);
  font-size: 18px;
  font-weight: 700;
  height: 41px;
  left: 50%;
  line-height: 38px;
  margin-left: -112px;
  padding-top: 3px;
  position: absolute;
  text-align: center;
  text-shadow: 0 -3px 0 rgba(0, 0, 0, 0.5);
  top: -20px;
  width: 230px;
}

.roulette_rule_text {
  word-spacing: 2px;
}

.roulette_rule_text + .roulette_rule_text {
  margin-top: 20px;
}

.roulette_nav {
  background-color: #190023;
  bottom: 0;
  box-shadow: 0 0 5.8666666667vw rgba(255, 255, 255, 0.2);
  height: 44px;
  left: 0;
  max-width: 568px;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.roulette_nav_wrap {
  display: flex;
}

.roulette_nav_item {
  flex: 1 1 0;
}

.roulette_nav_item > a {
  color: rgba(255, 255, 255, 0.5);
  font-size: 3.7333333333vw;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  transition: color 0.4s;
}

.roulette_nav_item > .active,
.roulette_nav_item > a:hover {
  color: #fff;
}

.roulette_popup {
  display: flex;
  max-width: 568px;
  position: fixed;
}

.roulette_popup,
.roulette_popup:before {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.roulette_popup:before {
  backdrop-filter: blur(0);
  background-color: transparent;
  content: '';
  position: absolute;
  transition: background-color 0.3s, backdrop-filter 0.3s;
}

.roulette_popup_wrap {
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulettePopup_box.png);
  background-size: cover;
  height: 0;
  margin: auto;
  opacity: 0;
  padding-bottom: 81.1855670103%;
  transform: scale3d(0.5, 0.5, 1);
  transition: opacity 0.4s 0.2s, transform 0.4s 0.2s;
  width: 100%;
}

.roulette_popup_box {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.roulette_popup_box:after,
.roulette_popup_box:before {
  background-size: cover;
  content: '';
  height: 80.9523809524%;
  left: 2%;
  pointer-events: none;
  position: absolute;
  top: 10%;
  width: 103.0927835052%;
}

.roulette_popup_box:before {
  animation-direction: alternate;
  animation-timing-function: linear;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulettePopup_blue.png);
  transform-origin: 45% 50%;
}

.roulette_popup_box:after {
  animation-direction: alternate-reverse;
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulettePopup_red.png);
  transform-origin: 50% 50%;
}

.roulette_popup_box > p {
  font-size: 3.7333333333vw;
  padding: 2.1333333333vw 0;
  text-shadow: 0 0 1.6vw #4d00bf, 0 0 3.2vw #4d00bf, 0 1px 3.2vw #4d00bf;
}

.roulette_popup_box > h3 {
  font-size: 5.3333333333vw;
  font-weight: 700;
  padding-bottom: 2.1333333333vw;
  position: relative;
  text-shadow: 0 0 4.8vw #4d00bf, 0 0 9.6vw #4d00bf, 0 1px 9.6vw #4d00bf;
  z-index: 1;
}

.roulette_popup_box > img {
  margin-bottom: 5px;
  position: relative;
  width: 37.3333333333vw;
  z-index: 1;
}

.roulette_popup_tenTimes {
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 38.9vw;
  justify-content: center;
  padding-top: 2.1333333333vw;
  position: relative;
  width: 80%;
  z-index: 1;
}

.roulette_popup_tenTimes > h3 {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 100px;
  display: flex;
  font-size: 11px;
  font-weight: 400;
  height: 17%;
  margin: 0.5% 2px;
  padding: 0 5px;
  text-shadow: 0 0 1px #4d00bf, 1px 1px 0 #4d00bf, 0 1px 0 #4d00bf, -1px 1px 0 #4d00bf, -1px 0 0 #4d00bf, -1px -1px 0 #4d00bf, 0 -1px 0 #4d00bf, 1px -1px 0 #4d00bf, 1px 0 0 #4d00bf,
    -1px -1px 0 #4d00bf, 0 0 1.6vw #4d00bf, 0 0 3.2vw #4d00bf, 0 1px 3.2vw #4d00bf;
  width: 50%;
}

.roulette_popup_tenTimes > h3 b {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.roulette_popup_tenTimes > h3:before {
  background-color: #6404f3;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #6404f3;
  display: inline-block;
  flex: 4vw 0 0;
  font-size: 3.2vw;
  font-weight: 400;
  height: 4vw;
  line-height: 4vw;
  margin-right: 1.3333333333vw;
  text-align: center;
  vertical-align: top;
  width: 4vw;
}

.roulette_popup_tenTimes > h3:first-of-type:before {
  content: '1';
}

.roulette_popup_tenTimes > h3:nth-of-type(2):before {
  content: '2';
}

.roulette_popup_tenTimes > h3:nth-of-type(3):before {
  content: '3';
}

.roulette_popup_tenTimes > h3:nth-of-type(4):before {
  content: '4';
}

.roulette_popup_tenTimes > h3:nth-of-type(5):before {
  content: '5';
}

.roulette_popup_tenTimes > h3:nth-of-type(6):before {
  content: '6';
}

.roulette_popup_tenTimes > h3:nth-of-type(7):before {
  content: '7';
}

.roulette_popup_tenTimes > h3:nth-of-type(8):before {
  content: '8';
}

.roulette_popup_tenTimes > h3:nth-of-type(9):before {
  content: '9';
}

.roulette_popup_tenTimes > h3:nth-of-type(10):before {
  content: '10';
}

.roulette_popup_btnWrap {
  display: flex;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.roulette_popup_btnWrap > .roulette_popup_btn {
  padding: 0 15px;
}

.roulette_popup_btnWrap > .roulette_popup_btn + .roulette_popup_btn {
  margin-left: 12px;
}

.roulette_popup_btn {
  background-color: rgba(0, 0, 0, 0.8);
  background-image: url(https://csi.20icipp.com/img/static/roulette/roulette_winner_btn_lm.png), url(https://csi.20icipp.com/img/static/roulette/roulette_winner_btn_cm.png),
    url(https://csi.20icipp.com/img/static/roulette/roulette_winner_btn_rm.png);
  background-position: 0 0, 50% 0, 100% 0;
  background-repeat: no-repeat;
  background-size: 15px 100%, calc(100% - 30px) 100%, 15px 100%;
  border-radius: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.8), 0 0 6px rgba(0, 0, 0, 0.5), inset 0 0 2px 2px rgba(0, 0, 0, 0.8);
  font-size: 14px;
  line-height: 30px;
  padding: 0 24px;
  text-align: center;
  text-shadow: 0 1px 2px #5a29a7;
}

.roulette_popup_btn.disabled {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.roulette_popup_close {
  height: 50px;
  padding: 7px;
  position: absolute;
  right: 8%;
  top: -7px;
  width: 50px;
  z-index: 2;
}

.roulette_popup_close > div {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  cursor: pointer;
  height: 100%;
}

.roulette_popup_close > div:after,
.roulette_popup_close > div:before {
  background-color: #000;
  border-radius: 2px;
  content: '';
  height: 2px;
  left: 50%;
  position: absolute;
  top: 50%;
  width: 24px;
}

.roulette_popup_close > div:before {
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.roulette_popup_close > div:after {
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

.roulette_main_btn.disabled {
  background-position: 0 0;
  pointer-events: none;
}

.roulette_main_btn.disabled:after {
  animation-name: none;
}

.roulette_main_btn.disabled .roulette_main_logo {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.roulette_main_wrap-rotate > .roulette_main_part {
  animation-iteration-count: infinite;
  animation-name: rouletteMainPart;
  animation-timing-function: linear;
}

.roulette_main_wrap-rotate > .roulette_main_part-9 {
  animation-duration: 0.3s;
}

.roulette_main_wrap-rotate > .roulette_main_part-8 {
  animation-duration: 0.275s;
}

.roulette_main_wrap-rotate > .roulette_main_part-7 {
  animation-duration: 0.25s;
}

.roulette_main_wrap-rotate > .roulette_main_part-6 {
  animation-duration: 0.225s;
}

.roulette_main_wrap-rotate .roulette_main_btn,
.roulette_main_wrap-rotate .roulette_main_btn:after {
  animation-name: none;
}

.roulette_main_wrap-rotate .roulette_main_btn {
  background-position: 0 0;
  pointer-events: none;
}

.roulette_main_wrap-rotate .roulette_main_logo {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.roulette_P6_N0 .roulette_main_part_list:first-of-type:before,
.roulette_P6_N0 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P6_N1 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P6_N1 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P6_N2 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P6_N2 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P6_N3 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P6_N3 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P6_N4 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P6_N4 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P6_N5 .roulette_main_part_list:first-of-type:before,
.roulette_P6_N5 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P7_N0 .roulette_main_part_list:first-of-type:before,
.roulette_P7_N0 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P7_N1 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P7_N1 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P7_N2 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P7_N2 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P7_N3 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P7_N3 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P7_N4 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P7_N4 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P7_N5 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P7_N5 .roulette_main_part_list:nth-of-type(7):before,
.roulette_P7_N6 .roulette_main_part_list:first-of-type:before,
.roulette_P7_N6 .roulette_main_part_list:nth-of-type(7):before,
.roulette_P8_N0 .roulette_main_part_list:first-of-type:before,
.roulette_P8_N0 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P8_N1 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P8_N1 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P8_N2 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P8_N2 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P8_N3 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P8_N3 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P8_N4 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P8_N4 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P8_N5 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P8_N5 .roulette_main_part_list:nth-of-type(7):before,
.roulette_P8_N6 .roulette_main_part_list:nth-of-type(7):before,
.roulette_P8_N6 .roulette_main_part_list:nth-of-type(8):before,
.roulette_P8_N7 .roulette_main_part_list:first-of-type:before,
.roulette_P8_N7 .roulette_main_part_list:nth-of-type(8):before,
.roulette_P9_N0 .roulette_main_part_list:first-of-type:before,
.roulette_P9_N0 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P9_N1 .roulette_main_part_list:nth-of-type(2):before,
.roulette_P9_N1 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P9_N2 .roulette_main_part_list:nth-of-type(3):before,
.roulette_P9_N2 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P9_N3 .roulette_main_part_list:nth-of-type(4):before,
.roulette_P9_N3 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P9_N4 .roulette_main_part_list:nth-of-type(5):before,
.roulette_P9_N4 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P9_N5 .roulette_main_part_list:nth-of-type(6):before,
.roulette_P9_N5 .roulette_main_part_list:nth-of-type(7):before,
.roulette_P9_N6 .roulette_main_part_list:nth-of-type(7):before,
.roulette_P9_N6 .roulette_main_part_list:nth-of-type(8):before,
.roulette_P9_N7 .roulette_main_part_list:nth-of-type(8):before,
.roulette_P9_N7 .roulette_main_part_list:nth-of-type(9):before,
.roulette_P9_N8 .roulette_main_part_list:first-of-type:before,
.roulette_P9_N8 .roulette_main_part_list:nth-of-type(9):before {
  animation-delay: 2.2s;
  animation-name: roulettePrizeNumberM;
}

.roulette_P6_N0 > .roulette_main_part,
.roulette_P6_N1 > .roulette_main_part,
.roulette_P6_N2 > .roulette_main_part,
.roulette_P6_N3 > .roulette_main_part,
.roulette_P6_N4 > .roulette_main_part,
.roulette_P6_N5 > .roulette_main_part,
.roulette_P6_N6 > .roulette_main_part,
.roulette_P6_N7 > .roulette_main_part,
.roulette_P6_N8 > .roulette_main_part,
.roulette_P7_N0 > .roulette_main_part,
.roulette_P7_N1 > .roulette_main_part,
.roulette_P7_N2 > .roulette_main_part,
.roulette_P7_N3 > .roulette_main_part,
.roulette_P7_N4 > .roulette_main_part,
.roulette_P7_N5 > .roulette_main_part,
.roulette_P7_N6 > .roulette_main_part,
.roulette_P7_N7 > .roulette_main_part,
.roulette_P7_N8 > .roulette_main_part,
.roulette_P8_N0 > .roulette_main_part,
.roulette_P8_N1 > .roulette_main_part,
.roulette_P8_N2 > .roulette_main_part,
.roulette_P8_N3 > .roulette_main_part,
.roulette_P8_N4 > .roulette_main_part,
.roulette_P8_N5 > .roulette_main_part,
.roulette_P8_N6 > .roulette_main_part,
.roulette_P8_N7 > .roulette_main_part,
.roulette_P8_N8 > .roulette_main_part,
.roulette_P9_N0 > .roulette_main_part,
.roulette_P9_N1 > .roulette_main_part,
.roulette_P9_N2 > .roulette_main_part,
.roulette_P9_N3 > .roulette_main_part,
.roulette_P9_N4 > .roulette_main_part,
.roulette_P9_N5 > .roulette_main_part,
.roulette_P9_N6 > .roulette_main_part,
.roulette_P9_N7 > .roulette_main_part,
.roulette_P9_N8 > .roulette_main_part {
  animation-duration: 2.2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.15, 1.1);
}

.roulette_P6_N0 .roulette_main_part_award,
.roulette_P6_N0 .roulette_main_part_list:after,
.roulette_P6_N1 .roulette_main_part_award,
.roulette_P6_N1 .roulette_main_part_list:after,
.roulette_P6_N2 .roulette_main_part_award,
.roulette_P6_N2 .roulette_main_part_list:after,
.roulette_P6_N3 .roulette_main_part_award,
.roulette_P6_N3 .roulette_main_part_list:after,
.roulette_P6_N4 .roulette_main_part_award,
.roulette_P6_N4 .roulette_main_part_list:after,
.roulette_P6_N5 .roulette_main_part_award,
.roulette_P6_N5 .roulette_main_part_list:after,
.roulette_P6_N6 .roulette_main_part_award,
.roulette_P6_N6 .roulette_main_part_list:after,
.roulette_P6_N7 .roulette_main_part_award,
.roulette_P6_N7 .roulette_main_part_list:after,
.roulette_P6_N8 .roulette_main_part_award,
.roulette_P6_N8 .roulette_main_part_list:after,
.roulette_P7_N0 .roulette_main_part_award,
.roulette_P7_N0 .roulette_main_part_list:after,
.roulette_P7_N1 .roulette_main_part_award,
.roulette_P7_N1 .roulette_main_part_list:after,
.roulette_P7_N2 .roulette_main_part_award,
.roulette_P7_N2 .roulette_main_part_list:after,
.roulette_P7_N3 .roulette_main_part_award,
.roulette_P7_N3 .roulette_main_part_list:after,
.roulette_P7_N4 .roulette_main_part_award,
.roulette_P7_N4 .roulette_main_part_list:after,
.roulette_P7_N5 .roulette_main_part_award,
.roulette_P7_N5 .roulette_main_part_list:after,
.roulette_P7_N6 .roulette_main_part_award,
.roulette_P7_N6 .roulette_main_part_list:after,
.roulette_P7_N7 .roulette_main_part_award,
.roulette_P7_N7 .roulette_main_part_list:after,
.roulette_P7_N8 .roulette_main_part_award,
.roulette_P7_N8 .roulette_main_part_list:after,
.roulette_P8_N0 .roulette_main_part_award,
.roulette_P8_N0 .roulette_main_part_list:after,
.roulette_P8_N1 .roulette_main_part_award,
.roulette_P8_N1 .roulette_main_part_list:after,
.roulette_P8_N2 .roulette_main_part_award,
.roulette_P8_N2 .roulette_main_part_list:after,
.roulette_P8_N3 .roulette_main_part_award,
.roulette_P8_N3 .roulette_main_part_list:after,
.roulette_P8_N4 .roulette_main_part_award,
.roulette_P8_N4 .roulette_main_part_list:after,
.roulette_P8_N5 .roulette_main_part_award,
.roulette_P8_N5 .roulette_main_part_list:after,
.roulette_P8_N6 .roulette_main_part_award,
.roulette_P8_N6 .roulette_main_part_list:after,
.roulette_P8_N7 .roulette_main_part_award,
.roulette_P8_N7 .roulette_main_part_list:after,
.roulette_P8_N8 .roulette_main_part_award,
.roulette_P8_N8 .roulette_main_part_list:after,
.roulette_P9_N0 .roulette_main_part_award,
.roulette_P9_N0 .roulette_main_part_list:after,
.roulette_P9_N1 .roulette_main_part_award,
.roulette_P9_N1 .roulette_main_part_list:after,
.roulette_P9_N2 .roulette_main_part_award,
.roulette_P9_N2 .roulette_main_part_list:after,
.roulette_P9_N3 .roulette_main_part_award,
.roulette_P9_N3 .roulette_main_part_list:after,
.roulette_P9_N4 .roulette_main_part_award,
.roulette_P9_N4 .roulette_main_part_list:after,
.roulette_P9_N5 .roulette_main_part_award,
.roulette_P9_N5 .roulette_main_part_list:after,
.roulette_P9_N6 .roulette_main_part_award,
.roulette_P9_N6 .roulette_main_part_list:after,
.roulette_P9_N7 .roulette_main_part_award,
.roulette_P9_N7 .roulette_main_part_list:after,
.roulette_P9_N8 .roulette_main_part_award,
.roulette_P9_N8 .roulette_main_part_list:after {
  animation-delay: 2.2s;
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  font-weight: bold;
  font-family: 'ReadexPro-Bold', sans-serif;
  transform: rotate(-90deg);
  color: #dd3f07;
  font-size: 1.2rem;
  text-shadow: -2px -2px 0 #ffffff, -2px -2px 0 #ffffff, -1px -2px 0 #ffffff, 0 -2px 0 #ffffff, 1px -2px 0 #ffffff, 1px -2px 0 #ffffff, 2px -2px 0 #ffffff, 2px -2px 0 #ffffff, 2px -2px 0 #ffffff,
    2px -1px 0 #ffffff, 2px -1px 0 #ffffff, 2px 0 0 #ffffff, 2px 1px 0 #ffffff, 2px 2px 0 #ffffff, 2px 1px 0 #ffffff, 2px 1px 0 #ffffff, 1px 1px 0 #ffffff, 2px 1px 0 #ffffff, 1px 1px 0 #ffffff,
    0 2px 0 #ffffff, -1px 1px 0 #ffffff, -2px 1px 0 #ffffff, -1px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 1px 0 #ffffff, -2px 0 0 #ffffff, -2px -1px 0 #ffffff,
    -2px -2px 0 #ffffff, -2px -1px 0 #ffffff, -2px -2px 0 #ffffff;
}

.rouletteMain_P8_N8 > .rouletteMain_box_part li:nth-child(2n + 1) > .rouletteMain_box_part_award {
  color: #036153;
}

.roulette_P6_N0 .roulette_main_btn,
.roulette_P6_N0 .roulette_main_btn:after,
.roulette_P6_N1 .roulette_main_btn,
.roulette_P6_N1 .roulette_main_btn:after,
.roulette_P6_N2 .roulette_main_btn,
.roulette_P6_N2 .roulette_main_btn:after,
.roulette_P6_N3 .roulette_main_btn,
.roulette_P6_N3 .roulette_main_btn:after,
.roulette_P6_N4 .roulette_main_btn,
.roulette_P6_N4 .roulette_main_btn:after,
.roulette_P6_N5 .roulette_main_btn,
.roulette_P6_N5 .roulette_main_btn:after,
.roulette_P6_N6 .roulette_main_btn,
.roulette_P6_N6 .roulette_main_btn:after,
.roulette_P6_N7 .roulette_main_btn,
.roulette_P6_N7 .roulette_main_btn:after,
.roulette_P6_N8 .roulette_main_btn,
.roulette_P6_N8 .roulette_main_btn:after,
.roulette_P7_N0 .roulette_main_btn,
.roulette_P7_N0 .roulette_main_btn:after,
.roulette_P7_N1 .roulette_main_btn,
.roulette_P7_N1 .roulette_main_btn:after,
.roulette_P7_N2 .roulette_main_btn,
.roulette_P7_N2 .roulette_main_btn:after,
.roulette_P7_N3 .roulette_main_btn,
.roulette_P7_N3 .roulette_main_btn:after,
.roulette_P7_N4 .roulette_main_btn,
.roulette_P7_N4 .roulette_main_btn:after,
.roulette_P7_N5 .roulette_main_btn,
.roulette_P7_N5 .roulette_main_btn:after,
.roulette_P7_N6 .roulette_main_btn,
.roulette_P7_N6 .roulette_main_btn:after,
.roulette_P7_N7 .roulette_main_btn,
.roulette_P7_N7 .roulette_main_btn:after,
.roulette_P7_N8 .roulette_main_btn,
.roulette_P7_N8 .roulette_main_btn:after,
.roulette_P8_N0 .roulette_main_btn,
.roulette_P8_N0 .roulette_main_btn:after,
.roulette_P8_N1 .roulette_main_btn,
.roulette_P8_N1 .roulette_main_btn:after,
.roulette_P8_N2 .roulette_main_btn,
.roulette_P8_N2 .roulette_main_btn:after,
.roulette_P8_N3 .roulette_main_btn,
.roulette_P8_N3 .roulette_main_btn:after,
.roulette_P8_N4 .roulette_main_btn,
.roulette_P8_N4 .roulette_main_btn:after,
.roulette_P8_N5 .roulette_main_btn,
.roulette_P8_N5 .roulette_main_btn:after,
.roulette_P8_N6 .roulette_main_btn,
.roulette_P8_N6 .roulette_main_btn:after,
.roulette_P8_N7 .roulette_main_btn,
.roulette_P8_N7 .roulette_main_btn:after,
.roulette_P8_N8 .roulette_main_btn,
.roulette_P8_N8 .roulette_main_btn:after,
.roulette_P9_N0 .roulette_main_btn,
.roulette_P9_N0 .roulette_main_btn:after,
.roulette_P9_N1 .roulette_main_btn,
.roulette_P9_N1 .roulette_main_btn:after,
.roulette_P9_N2 .roulette_main_btn,
.roulette_P9_N2 .roulette_main_btn:after,
.roulette_P9_N3 .roulette_main_btn,
.roulette_P9_N3 .roulette_main_btn:after,
.roulette_P9_N4 .roulette_main_btn,
.roulette_P9_N4 .roulette_main_btn:after,
.roulette_P9_N5 .roulette_main_btn,
.roulette_P9_N5 .roulette_main_btn:after,
.roulette_P9_N6 .roulette_main_btn,
.roulette_P9_N6 .roulette_main_btn:after,
.roulette_P9_N7 .roulette_main_btn,
.roulette_P9_N7 .roulette_main_btn:after,
.roulette_P9_N8 .roulette_main_btn,
.roulette_P9_N8 .roulette_main_btn:after {
  animation-delay: 2.2s;
}

.roulette_P6_N0 .roulette_main_btn,
.roulette_P6_N0 .roulette_main_logo,
.roulette_P6_N1 .roulette_main_btn,
.roulette_P6_N1 .roulette_main_logo,
.roulette_P6_N2 .roulette_main_btn,
.roulette_P6_N2 .roulette_main_logo,
.roulette_P6_N3 .roulette_main_btn,
.roulette_P6_N3 .roulette_main_logo,
.roulette_P6_N4 .roulette_main_btn,
.roulette_P6_N4 .roulette_main_logo,
.roulette_P6_N5 .roulette_main_btn,
.roulette_P6_N5 .roulette_main_logo,
.roulette_P6_N6 .roulette_main_btn,
.roulette_P6_N6 .roulette_main_logo,
.roulette_P6_N7 .roulette_main_btn,
.roulette_P6_N7 .roulette_main_logo,
.roulette_P6_N8 .roulette_main_btn,
.roulette_P6_N8 .roulette_main_logo,
.roulette_P7_N0 .roulette_main_btn,
.roulette_P7_N0 .roulette_main_logo,
.roulette_P7_N1 .roulette_main_btn,
.roulette_P7_N1 .roulette_main_logo,
.roulette_P7_N2 .roulette_main_btn,
.roulette_P7_N2 .roulette_main_logo,
.roulette_P7_N3 .roulette_main_btn,
.roulette_P7_N3 .roulette_main_logo,
.roulette_P7_N4 .roulette_main_btn,
.roulette_P7_N4 .roulette_main_logo,
.roulette_P7_N5 .roulette_main_btn,
.roulette_P7_N5 .roulette_main_logo,
.roulette_P7_N6 .roulette_main_btn,
.roulette_P7_N6 .roulette_main_logo,
.roulette_P7_N7 .roulette_main_btn,
.roulette_P7_N7 .roulette_main_logo,
.roulette_P7_N8 .roulette_main_btn,
.roulette_P7_N8 .roulette_main_logo,
.roulette_P8_N0 .roulette_main_btn,
.roulette_P8_N0 .roulette_main_logo,
.roulette_P8_N1 .roulette_main_btn,
.roulette_P8_N1 .roulette_main_logo,
.roulette_P8_N2 .roulette_main_btn,
.roulette_P8_N2 .roulette_main_logo,
.roulette_P8_N3 .roulette_main_btn,
.roulette_P8_N3 .roulette_main_logo,
.roulette_P8_N4 .roulette_main_btn,
.roulette_P8_N4 .roulette_main_logo,
.roulette_P8_N5 .roulette_main_btn,
.roulette_P8_N5 .roulette_main_logo,
.roulette_P8_N6 .roulette_main_btn,
.roulette_P8_N6 .roulette_main_logo,
.roulette_P8_N7 .roulette_main_btn,
.roulette_P8_N7 .roulette_main_logo,
.roulette_P8_N8 .roulette_main_btn,
.roulette_P8_N8 .roulette_main_logo,
.roulette_P9_N0 .roulette_main_btn,
.roulette_P9_N0 .roulette_main_logo,
.roulette_P9_N1 .roulette_main_btn,
.roulette_P9_N1 .roulette_main_logo,
.roulette_P9_N2 .roulette_main_btn,
.roulette_P9_N2 .roulette_main_logo,
.roulette_P9_N3 .roulette_main_btn,
.roulette_P9_N3 .roulette_main_logo,
.roulette_P9_N4 .roulette_main_btn,
.roulette_P9_N4 .roulette_main_logo,
.roulette_P9_N5 .roulette_main_btn,
.roulette_P9_N5 .roulette_main_logo,
.roulette_P9_N6 .roulette_main_btn,
.roulette_P9_N6 .roulette_main_logo,
.roulette_P9_N7 .roulette_main_btn,
.roulette_P9_N7 .roulette_main_logo,
.roulette_P9_N8 .roulette_main_btn,
.roulette_P9_N8 .roulette_main_logo {
  transition-delay: 2.2s;
}

.roulette_P6_N0 > .roulette_main_part,
.roulette_P7_N0 > .roulette_main_part,
.roulette_P8_N0 > .roulette_main_part,
.roulette_P9_N0 > .roulette_main_part {
  animation-name: roulette_P6789_N0;
}

.roulette_P6_N0 .roulette_main_part_list:first-of-type:after,
.roulette_P7_N0 .roulette_main_part_list:first-of-type:after,
.roulette_P8_N0 .roulette_main_part_list:first-of-type:after,
.roulette_P9_N0 .roulette_main_part_list:first-of-type:after {
  animation-name: roulettePrizeNumber0ShadowM;
}

.roulette_P6_N0 .roulette_main_part_list:first-of-type > .roulette_main_part_award,
.roulette_P7_N0 .roulette_main_part_list:first-of-type > .roulette_main_part_award,
.roulette_P8_N0 .roulette_main_part_list:first-of-type > .roulette_main_part_award,
.roulette_P9_N0 .roulette_main_part_list:first-of-type > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P6_N1 .roulette_main_part_list:nth-of-type(2):after,
.roulette_P7_N1 .roulette_main_part_list:nth-of-type(2):after,
.roulette_P8_N1 .roulette_main_part_list:nth-of-type(2):after,
.roulette_P9_N1 .roulette_main_part_list:nth-of-type(2):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P6_N1 .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_award,
.roulette_P7_N1 .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_award,
.roulette_P8_N1 .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_award,
.roulette_P9_N1 .roulette_main_part_list:nth-of-type(2) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P6_N2 .roulette_main_part_list:nth-of-type(3):after,
.roulette_P7_N2 .roulette_main_part_list:nth-of-type(3):after,
.roulette_P8_N2 .roulette_main_part_list:nth-of-type(3):after,
.roulette_P9_N2 .roulette_main_part_list:nth-of-type(3):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P6_N2 .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_award,
.roulette_P7_N2 .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_award,
.roulette_P8_N2 .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_award,
.roulette_P9_N2 .roulette_main_part_list:nth-of-type(3) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P6_N3 .roulette_main_part_list:nth-of-type(4):after,
.roulette_P7_N3 .roulette_main_part_list:nth-of-type(4):after,
.roulette_P8_N3 .roulette_main_part_list:nth-of-type(4):after,
.roulette_P9_N3 .roulette_main_part_list:nth-of-type(4):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P6_N3 .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_award,
.roulette_P7_N3 .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_award,
.roulette_P8_N3 .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_award,
.roulette_P9_N3 .roulette_main_part_list:nth-of-type(4) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P6_N4 .roulette_main_part_list:nth-of-type(5):after,
.roulette_P7_N4 .roulette_main_part_list:nth-of-type(5):after,
.roulette_P8_N4 .roulette_main_part_list:nth-of-type(5):after,
.roulette_P9_N4 .roulette_main_part_list:nth-of-type(5):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P6_N4 .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_award,
.roulette_P7_N4 .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_award,
.roulette_P8_N4 .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_award,
.roulette_P9_N4 .roulette_main_part_list:nth-of-type(5) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P6_N5 .roulette_main_part_list:nth-of-type(6):after,
.roulette_P7_N5 .roulette_main_part_list:nth-of-type(6):after,
.roulette_P8_N5 .roulette_main_part_list:nth-of-type(6):after,
.roulette_P9_N5 .roulette_main_part_list:nth-of-type(6):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P6_N5 .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_award,
.roulette_P7_N5 .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_award,
.roulette_P8_N5 .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_award,
.roulette_P9_N5 .roulette_main_part_list:nth-of-type(6) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P7_N6 .roulette_main_part_list:nth-of-type(7):after,
.roulette_P8_N6 .roulette_main_part_list:nth-of-type(7):after,
.roulette_P9_N6 .roulette_main_part_list:nth-of-type(7):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P7_N6 .roulette_main_part_list:nth-of-type(7) > .roulette_main_part_award,
.roulette_P8_N6 .roulette_main_part_list:nth-of-type(7) > .roulette_main_part_award,
.roulette_P9_N6 .roulette_main_part_list:nth-of-type(7) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P8_N7 .roulette_main_part_list:nth-of-type(8):after,
.roulette_P9_N7 .roulette_main_part_list:nth-of-type(8):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P8_N7 .roulette_main_part_list:nth-of-type(8) > .roulette_main_part_award,
.roulette_P9_N7 .roulette_main_part_list:nth-of-type(8) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P9_N1 > .roulette_main_part {
  animation-name: roulette_P9_N1;
}

.roulette_P9_N2 > .roulette_main_part {
  animation-name: roulette_P9_N2;
}

.roulette_P9_N3 > .roulette_main_part {
  animation-name: roulette_P6_N2_P9_N3;
}

.roulette_P9_N4 > .roulette_main_part {
  animation-name: roulette_P9_N4;
}

.roulette_P9_N5 > .roulette_main_part {
  animation-name: roulette_P9_N5;
}

.roulette_P9_N6 > .roulette_main_part {
  animation-name: roulette_P6_N4_P9_N6;
}

.roulette_P9_N7 > .roulette_main_part {
  animation-name: roulette_P9_N7;
}

.roulette_P9_N8 > .roulette_main_part {
  animation-name: roulette_P9_N8;
}

.roulette_P9_N8 .roulette_main_part_list:nth-of-type(9):after {
  animation-name: roulettePrizeNumberTextM;
}

.roulette_P9_N8 .roulette_main_part_list:nth-of-type(9) > .roulette_main_part_award {
  animation-name: roulettePrizeNumberAwardM;
}

.roulette_P8_N1 > .roulette_main_part {
  animation-name: roulette_P8_N1;
}

.roulette_P8_N2 > .roulette_main_part {
  animation-name: roulette_P8_N2;
}

.roulette_P8_N3 > .roulette_main_part {
  animation-name: roulette_P8_N3;
}

.roulette_P8_N4 > .roulette_main_part {
  animation-name: roulette_P6_N3_P8_N4;
}

.roulette_P8_N5 > .roulette_main_part {
  animation-name: roulette_P8_N5;
}

.roulette_P8_N6 > .roulette_main_part {
  animation-name: roulette_P8_N6;
}

.roulette_P8_N7 > .roulette_main_part {
  animation-name: roulette_P8_N7;
}

.roulette_P7_N1 > .roulette_main_part {
  animation-name: roulette_P7_N1;
}

.roulette_P7_N2 > .roulette_main_part {
  animation-name: roulette_P7_N2;
}

.roulette_P7_N3 > .roulette_main_part {
  animation-name: roulette_P7_N3;
}

.roulette_P7_N4 > .roulette_main_part {
  animation-name: roulette_P7_N4;
}

.roulette_P7_N5 > .roulette_main_part {
  animation-name: roulette_P7_N5;
}

.roulette_P7_N6 > .roulette_main_part {
  animation-name: roulette_P7_N6;
}

.roulette_P6_N1 > .roulette_main_part {
  animation-name: roulette_P6_N1;
}

.roulette_P6_N2 > .roulette_main_part {
  animation-name: roulette_P6_N2_P9_N3;
}

.roulette_P6_N3 > .roulette_main_part {
  animation-name: roulette_P6_N3_P8_N4;
}

.roulette_P6_N4 > .roulette_main_part {
  animation-name: roulette_P6_N4_P9_N6;
}

.roulette_P6_N5 > .roulette_main_part {
  animation-name: roulette_P6_N5;
}

.roulette-popupOpen .roulette_awards_item:first-of-type > div,
.roulette-popupOpen .roulette_main:after,
.roulette-popupOpen .roulette_main:before,
.roulette-popupOpen .roulette_main_btn,
.roulette-popupOpen .roulette_main_btn:after,
.roulette-popupOpen .roulette_main_coinWrap,
.roulette-popupOpen .roulette_main_part_award,
.roulette-popupOpen .roulette_main_part_list:after,
.roulette-popupOpen .roulette_main_part_list:before,
.roulette-popupOpen .roulette_main_wrap:after {
  animation-play-state: paused;
}

.roulette-popupOpen .roulette_popup {
  z-index: 3;
}

.roulette-popupOpen .roulette_popup:before {
  backdrop-filter: blur(6px);
  background-color: rgba(0, 0, 0, 0.7);
}

.roulette-popupOpen .roulette_popup_wrap {
  opacity: 1;
  transform: scaleX(1);
}

.roulette-popupOpen .roulette_popup_box:after,
.roulette-popupOpen .roulette_popup_box:before {
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-name: rouletteDecoShake;
}

@media screen and (min-width: 568px) {
  .roulette_P6_N1 .roulette_main_part_list:nth-of-type(2):before,
  .roulette_P6_N1 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P6_N2 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P6_N2 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P6_N3 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P6_N3 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P6_N4 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P6_N4 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P6_N5 .roulette_main_part_list:first-of-type:before,
  .roulette_P6_N5 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P7_N1 .roulette_main_part_list:nth-of-type(2):before,
  .roulette_P7_N1 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P7_N2 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P7_N2 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P7_N3 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P7_N3 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P7_N4 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P7_N4 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P7_N5 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P7_N5 .roulette_main_part_list:nth-of-type(7):before,
  .roulette_P7_N6 .roulette_main_part_list:first-of-type:before,
  .roulette_P7_N6 .roulette_main_part_list:nth-of-type(7):before,
  .roulette_P8_N1 .roulette_main_part_list:nth-of-type(2):before,
  .roulette_P8_N1 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P8_N2 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P8_N2 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P8_N3 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P8_N3 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P8_N4 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P8_N4 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P8_N5 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P8_N5 .roulette_main_part_list:nth-of-type(7):before,
  .roulette_P8_N6 .roulette_main_part_list:nth-of-type(7):before,
  .roulette_P8_N6 .roulette_main_part_list:nth-of-type(8):before,
  .roulette_P8_N7 .roulette_main_part_list:first-of-type:before,
  .roulette_P8_N7 .roulette_main_part_list:nth-of-type(8):before,
  .roulette_P9_N1 .roulette_main_part_list:nth-of-type(2):before,
  .roulette_P9_N1 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P9_N2 .roulette_main_part_list:nth-of-type(3):before,
  .roulette_P9_N2 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P9_N3 .roulette_main_part_list:nth-of-type(4):before,
  .roulette_P9_N3 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P9_N4 .roulette_main_part_list:nth-of-type(5):before,
  .roulette_P9_N4 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P9_N5 .roulette_main_part_list:nth-of-type(6):before,
  .roulette_P9_N5 .roulette_main_part_list:nth-of-type(7):before,
  .roulette_P9_N6 .roulette_main_part_list:nth-of-type(7):before,
  .roulette_P9_N6 .roulette_main_part_list:nth-of-type(8):before,
  .roulette_P9_N7 .roulette_main_part_list:nth-of-type(8):before,
  .roulette_P9_N7 .roulette_main_part_list:nth-of-type(9):before,
  .roulette_P9_N8 .roulette_main_part_list:first-of-type:before,
  .roulette_P9_N8 .roulette_main_part_list:nth-of-type(9):before {
    animation-name: roulettePrizeNumberM-pigSize;
  }

  .roulette_P6_N1 .roulette_main_part_list:nth-of-type(2):after,
  .roulette_P6_N2 .roulette_main_part_list:nth-of-type(3):after,
  .roulette_P6_N3 .roulette_main_part_list:nth-of-type(4):after,
  .roulette_P6_N4 .roulette_main_part_list:nth-of-type(5):after,
  .roulette_P6_N5 .roulette_main_part_list:nth-of-type(6):after,
  .roulette_P7_N1 .roulette_main_part_list:nth-of-type(2):after,
  .roulette_P7_N2 .roulette_main_part_list:nth-of-type(3):after,
  .roulette_P7_N3 .roulette_main_part_list:nth-of-type(4):after,
  .roulette_P7_N4 .roulette_main_part_list:nth-of-type(5):after,
  .roulette_P7_N5 .roulette_main_part_list:nth-of-type(6):after,
  .roulette_P7_N6 .roulette_main_part_list:nth-of-type(7):after,
  .roulette_P8_N1 .roulette_main_part_list:nth-of-type(2):after,
  .roulette_P8_N2 .roulette_main_part_list:nth-of-type(3):after,
  .roulette_P8_N3 .roulette_main_part_list:nth-of-type(4):after,
  .roulette_P8_N4 .roulette_main_part_list:nth-of-type(5):after,
  .roulette_P8_N5 .roulette_main_part_list:nth-of-type(6):after,
  .roulette_P8_N6 .roulette_main_part_list:nth-of-type(7):after,
  .roulette_P8_N7 .roulette_main_part_list:nth-of-type(8):after,
  .roulette_P9_N1 .roulette_main_part_list:nth-of-type(2):after,
  .roulette_P9_N2 .roulette_main_part_list:nth-of-type(3):after,
  .roulette_P9_N3 .roulette_main_part_list:nth-of-type(4):after,
  .roulette_P9_N4 .roulette_main_part_list:nth-of-type(5):after,
  .roulette_P9_N5 .roulette_main_part_list:nth-of-type(6):after,
  .roulette_P9_N6 .roulette_main_part_list:nth-of-type(7):after,
  .roulette_P9_N7 .roulette_main_part_list:nth-of-type(8):after,
  .roulette_P9_N8 .roulette_main_part_list:nth-of-type(9):after {
    animation-name: roulettePrizeNumberTextM-pigSize;
  }

  .roulette_wrap {
    box-shadow: 0 0 40px 2px rgba(0, 0, 0, 0.75);
  }

  .roulette_main_part_award {
    padding-bottom: 16px;
  }

  .roulette_main_part_list {
    font-size: 20px;
  }

  .roulette_main_part_list:first-of-type:after {
    -webkit-text-stroke: 4px #dd3f07;
  }

  .roulette_main_part_list:after {
    font-size: 64px;
    text-shadow: -3px -4px 0 #dd3f07, -2px -4px 0 #dd3f07, -1px -4px 0 #dd3f07, 0 -4px 0 #dd3f07, 1px -4px 0 #dd3f07, 2px -4px 0 #dd3f07, 3px -4px 0 #dd3f07, 4px -4px 0 #dd3f07, 4px -3px 0 #dd3f07,
      4px -2px 0 #dd3f07, 4px -1px 0 #dd3f07, 4px 0 0 #dd3f07, 4px 1px 0 #dd3f07, 4px 2px 0 #dd3f07, 4px 3px 0 #dd3f07, 4px 4px 0 #dd3f07, 3px 4px 0 #dd3f07, 2px 4px 0 #dd3f07, 1px 4px 0 #dd3f07,
      0 4px 0 #dd3f07, -1px 4px 0 #dd3f07, -2px 4px 0 #dd3f07, -3px 4px 0 #dd3f07, -4px 4px 0 #dd3f07, -4px 3px 0 #dd3f07, -4px 2px 0 #dd3f07, -4px 1px 0 #dd3f07, -4px 0 0 #dd3f07, -4px -1px 0 #dd3f07,
      -4px -2px 0 #dd3f07, -4px -3px 0 #dd3f07, -4px -4px 0 #dd3f07;
  }

  .roulette_main_numberTimes > div {
    text-shadow: 2px 3px 0 #f3cb00;
  }

  .roulette_main_numberTimes span:first-of-type {
    font-size: 40px;
  }

  .roulette_main_numberTimes span:nth-of-type(2) {
    font-size: 18px;
  }

  .roulette_main_btn {
    animation-name: rouletteMainBtnShadow-pigSize;
  }

  .roulette_awards_item:first-of-type > div {
    animation-name: rouletteSpecialAwardM-pigSize;
  }

  .roulette_awards_medals {
    font-size: 21px;
  }

  .roulette_awards_text {
    font-size: 24px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
  }

  .roulette_winner_wrap {
    padding: 23px;
  }

  .roulette_winner_headItem {
    font-size: 18px;
  }

  .roulette_winner_date {
    font-size: 16px;
  }

  .roulette_winner_listItem {
    font-size: 20px;
    line-height: 40px;
  }

  .roulette_nav {
    box-shadow: 0 -10px 20px -9px rgba(255, 255, 255, 0.2);
    left: 50%;
    margin-left: -284px;
  }

  .roulette_nav_item > a {
    font-size: 18px;
  }

  .roulette_popup {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .roulette_popup_box > p {
    font-size: 21px;
    padding: 12px 0;
  }

  .roulette_popup_box > h3 {
    font-size: 30px;
    padding-bottom: 12px;
    text-shadow: 0 0 27px #4d00bf, 0 0 54px #4d00bf, 0 1px 54px #4d00bf;
  }

  .roulette_popup_box > img {
    width: 227px;
  }

  .roulette_popup_tenTimes {
    height: 220px;
    padding-bottom: 12px;
  }

  .roulette_popup_tenTimes > h3 {
    font-size: 23px;
    padding-bottom: 12px;
    text-shadow: 0 0 1px #4d00bf, 1px 1px 0 #4d00bf, 0 1px 0 #4d00bf, -1px 1px 0 #4d00bf, -1px 0 0 #4d00bf, -1px -1px 0 #4d00bf, 0 -1px 0 #4d00bf, 1px -1px 0 #4d00bf, 1px 0 0 #4d00bf,
      -1px -1px 0 #4d00bf, 0 0 9px #4d00bf, 0 0 18px #4d00bf, 0 1px 18px #4d00bf;
  }

  .roulette_popup_tenTimes > h3:before {
    font-size: 18px;
    height: 23px;
    line-height: 23px;
    margin-right: 8px;
    width: 23px;
  }

  .roulette_P6_N0 .roulette_main_part_list:first-of-type:after,
  .roulette_P7_N0 .roulette_main_part_list:first-of-type:after,
  .roulette_P8_N0 .roulette_main_part_list:first-of-type:after,
  .roulette_P9_N0 .roulette_main_part_list:first-of-type:after {
    animation-name: roulettePrizeNumber0ShadowM-pigSize;
  }
}

@media screen and (min-width: 768px) {
  .roulette_wrap {
    overflow: visible;
  }

  .roulette.roulette-popupOpen {
    overflow: hidden;
  }
}

.roulette .roulette-period {
  color: #ffefbd;
  font-size: 18px;
  position: absolute;
  text-align: center;
  text-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
  top: 108vw;
  width: 100%;
  z-index: 1;
}

.roulette .unavailable {
  align-content: center;
  align-items: center;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-shadow: 0 2px 5px #000;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.roulette .unavailable h2 {
  color: #ffefbd;
  font-size: 29px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.roulette .unavailable i {
  font-size: 80px;
}

.roulette .unavailable + div {
  filter: grayscale(0.7) blur(2px) brightness(0.3);
}
</style>
